import { Container, Typography } from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { getOrganizationsByParentIds } from '@app/adapter/organization-service';
import { HomeCarousel } from '@app/components/Home/HomeCarousel';
import { ProductCardList } from '@app/components/Product/ProductCardList';
import { SearchNoResult } from '@app/components/Search/SearchNoResult';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { Loading } from '@app/components/Shared/Loading';
import { searchResultProductsState } from '@app/domain/catalog';
import { getProducts } from '@app/domain/network-actions';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { BOTTOM_MENU_ITEMS } from '@app/static/constants';
import { theme } from '@app/theme';
import { CategoryType, CategoryTypeKey } from '@app/types/catalog';
import { LoadableState } from '@app/types/common';

export function Home(): ReactElement {
  const navigate = useNavigate();
  const { isDesktop } = useDeviceType();
  const location = useLocation();
  const [resultProducts, setResultProducts] = useRecoilState(
    searchResultProductsState
  );
  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );
  const [searchType, setSearchType] = useState<CategoryTypeKey>(
    CategoryType.EXHIBITION
  );
  const setSnackbar = useSetSnackbar();

  const getSearchParams = useCallback(() => {
    const params = new URLSearchParams(location.search);
    const category = params.get('category') as CategoryTypeKey;
    const organizationId = params.get('org');
    const childrenIds = params.get('child')?.split(',') || [];

    const categoryKey = Object.values(CategoryType).includes(category)
      ? category
      : undefined;

    return {
      categoryKey,
      childrenIds,
      filter: organizationId ? `parentId eq '${organizationId}'` : undefined,
      locationIds: params.get('locations')
        ? [params.get('locations') as string]
        : [],
      organizationId: organizationId ? [organizationId as string] : [],
    };
  }, [location.search]);

  const updateUrlWithChildrenIds = useCallback(
    (childrenIds: string[]) => {
      const params = new URLSearchParams(location.search);
      if (childrenIds.length > 0) {
        params.set('child', childrenIds.join(','));
      } else {
        params.delete('child');
      }
      navigate(`?${params.toString()}`, { replace: true });
    },
    [location.search, navigate]
  );

  const fetchProducts = useCallback(
    async (nextLink?: string) => {
      if (loadable === LoadableState.LOADING) return;
      setLoadable(LoadableState.LOADING);
      try {
        const searchParams = getSearchParams();

        let childrenIds: string[] = [];
        if (searchParams.organizationId.length > 0) {
          const parentOrganizations = await getOrganizationsByParentIds(
            searchParams.organizationId
          );
          childrenIds =
            parentOrganizations?.data?.value?.map(
              (org: { id: string }) => org.id
            ) || [];
          updateUrlWithChildrenIds(childrenIds);
        }

        const categoryKey = searchParams.categoryKey || CategoryType.EXHIBITION;

        const options = {
          nextLink,
          ...searchParams,
          category: categoryKey,
          childrenIds: childrenIds.length > 0 ? childrenIds : undefined,
          expand: 'images,organization,locations',
          limit: 10,
        };

        const productResult = await getProducts(options);
        setResultProducts((prevState) => ({
          data: nextLink
            ? [...prevState.data, ...productResult.data.value]
            : productResult.data.value,
          nextLink: productResult.data['@nextLink'],
          total: productResult.data.total,
        }));
        setLoadable(LoadableState.HAS_VALUE);
      } catch (error) {
        setLoadable(LoadableState.HAS_ERROR);
        setSnackbar(true, 'データの取得に失敗しました');
      }
    },
    [
      loadable,
      setSnackbar,
      setResultProducts,
      getSearchParams,
      updateUrlWithChildrenIds,
    ]
  );

  useEffect(() => {
    setResultProducts({
      data: [],
      nextLink: '',
      scrollY: 0,
      total: 0,
    });
    void fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const title =
    searchType === CategoryType.EXHIBITION ? '新着住宅展示場' : '新着分譲地';

  return (
    <>
      <HeadBlock />
      <HomeCarousel setSearchType={setSearchType} />
      <Container
        maxWidth="lg"
        disableGutters={!isDesktop}
        sx={{ pb: '16px!important' }}
      >
        <Typography
          sx={{
            color: theme.palette.primary.dark,
            fontSize: '14px',
            pt: 6,
            textAlign: 'center',
          }}
        >
          おすすめ情報
        </Typography>
        <Typography
          sx={{
            color: 'black',
            fontSize: '1.25rem',
            fontWeight: 'bold',
            pt: 2,
            textAlign: 'center',
          }}
        >
          {title}
        </Typography>
      </Container>
      {loadable === LoadableState.LOADING && <Loading />}
      {loadable === LoadableState.HAS_VALUE &&
      resultProducts.data.length === 0 ? (
        <SearchNoResult />
      ) : (
        <ProductCardList
          products={resultProducts.data}
          initialVisibleItems={6}
          isProperty={searchType !== CategoryType.EXHIBITION}
        />
      )}
      <BottomMenu menuItems={BOTTOM_MENU_ITEMS} shouldShowMenuItems={false} />
    </>
  );
}
