import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Alert,
  AppBar,
  Box,
  IconButton,
  Snackbar,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { RiUserFollowLine, RiUserAddLine } from 'react-icons/ri';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { TopNavMenu } from '@app/components/TopNav/TopNavMenu';
import { userAuthInfoSelector } from '@app/domain/app';
import {
  navPortalContainerRef,
  navPortalSpacerRef,
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
} from '@app/domain/top-nav';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { imageAssets, theme } from '@app/theme';

interface TopNavProps {
  showAccount?: boolean;
}

export function TopNav({ showAccount = true }: TopNavProps) {
  const [isSnackbarOpen, setIsSnackbarOpen] = useRecoilState(snackbarOpenState);
  const [snackbarText, setSnackbarText] = useRecoilState(snackbarTextState);
  const [snackbarSeverity, setSnackbarSeverity] = useRecoilState(
    snackbarSeverityState
  );
  const navigate = useNavigate();
  const authInfo = useRecoilValue(userAuthInfoSelector);

  const [portalContainer, setPortalContainer] = useRecoilState(
    navPortalContainerRef
  );
  const portalContainerRef = useRef(null);

  // 画面幅によるSP表示判定フラグ
  const { isMobile } = useDeviceType();

  useEffect(() => {
    if (portalContainer?.current) {
      return;
    }
    setPortalContainer(portalContainerRef);
  }, [portalContainerRef, portalContainer, setPortalContainer]);

  const portalSpacerRef = useRef(null);
  const [portalSpacer, setPortalSpacer] = useRecoilState(navPortalSpacerRef);
  useEffect(() => {
    if (portalSpacer?.current) {
      return;
    }
    setPortalSpacer(portalSpacerRef);
  }, [portalSpacerRef, portalSpacer, setPortalSpacer]);

  useEffect(() => {
    if (!isSnackbarOpen) {
      setSnackbarText('');
      setSnackbarSeverity('error');
    }
  }, [isSnackbarOpen, setSnackbarText, setSnackbarSeverity]);

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'white',
          borderBottom: '1px solid #E0E0E0',
          boxShadow: 'none',
        }}
      >
        <Toolbar sx={{ minHeight: '64px' }}>
          <Box
            display="flex"
            alignItems="center"
            onClick={() => navigate('/home')}
          >
            <img
              src={imageAssets.logo}
              alt="Logo"
              style={{ height: 'auto', width: '145px' }}
            />
          </Box>
          <Box flexGrow="1" />
          {authInfo && (
            <IconButton
              component={Link}
              to="/notifications"
              sx={{
                color: theme.palette.primary.dark,
              }}
            >
              <NotificationsIcon />
              {!isMobile && <Typography sx={{ pl: 1 }}>お知らせ</Typography>}
            </IconButton>
          )}
          {/**
           * SP：メニューボタンを表示
           * PC：マイアカウント／会員登録ボタンを表示
           */}
          {isMobile ? (
            <TopNavMenu />
          ) : showAccount && authInfo?.userId ? (
            <IconButton
              component={Link}
              to="/account"
              sx={{
                color: theme.palette.primary.dark,
              }}
            >
              <RiUserFollowLine />
              <Typography sx={{ pl: 1 }}>マイアカウント</Typography>
            </IconButton>
          ) : (
            <IconButton
              component={Link}
              to="/register"
              sx={{
                color: theme.palette.primary.dark,
              }}
            >
              <RiUserAddLine />
              <Typography sx={{ pl: 1 }}>会員登録</Typography>
            </IconButton>
          )}
        </Toolbar>
        <Box ref={portalContainerRef} />
        <Snackbar
          open={isSnackbarOpen}
          autoHideDuration={4000}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={() => setIsSnackbarOpen(false)}
          data-e2e="snackBar"
        >
          <Alert
            onClose={() => setIsSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: '100%' }}
          >
            {snackbarText}
          </Alert>
        </Snackbar>
      </AppBar>
      <Box ref={portalSpacerRef} />
    </>
  );
}
