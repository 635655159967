import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Link,
} from '@mui/material';
import React from 'react';

import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { theme } from '@app/theme';
import { CategoryType, ProductVariant } from '@app/types/catalog';
import { formatPrice } from '@app/utils/format';

interface PropertyFloorPlanProps {
  organizationId: string;
  parentOrganizationId?: string;
  productId?: string;
  variants: ProductVariant[];
}

export function PropertyFloorPlan({
  productId,
  variants,
  parentOrganizationId,
}: PropertyFloorPlanProps) {
  const { isMobile } = useDeviceType();
  return (
    <Box sx={{ mb: isMobile ? 3 : 10, mt: 3 }}>
      <Typography variant="h6" fontWeight={600} sx={{ my: 2 }}>
        間取り・プラン
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        公開中: {variants.length}件
      </Typography>
      <Grid container spacing={2}>
        {variants.length > 0 && (
          <>
            {variants.map((variant) => (
              <Grid item xs={isMobile ? 12 : 6} key={variant.id}>
                <Card
                  sx={{
                    border: `1px solid ${theme.palette.neutral.silver}`,
                    height: '100%',
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="subtitle1"
                      fontWeight={600}
                      sx={{ my: 2 }}
                    >
                      {variant.title}
                    </Typography>
                    <Typography variant="body2" sx={{ my: 2 }}>
                      間取り: {variant.description || '-'}
                    </Typography>
                    <Typography variant="body2" sx={{ my: 2 }}>
                      土地面積: {variant.customFields?.landArea || '-'} ㎡
                    </Typography>
                    <Typography variant="body2" sx={{ my: 2 }}>
                      建物面積: {variant.customFields?.buildingArea || '-'} ㎡
                    </Typography>
                    <Typography variant="body2" sx={{ my: 2 }}>
                      販売価格:
                      {variant.customFields?.price &&
                      variant.customFields?.price.trim() !== '' ? (
                        <>
                          {formatPrice(Number(variant.customFields.price))} 万円
                        </>
                      ) : (
                        <>
                          <Link
                            href={`/companies/${parentOrganizationId}/inquiry/create?category=${CategoryType.PROPERTY}&productId=${productId}`}
                            color="primary"
                          >
                            価格をお問い合わせ（無料）
                          </Link>
                        </>
                      )}
                    </Typography>
                    <Typography variant="body2" sx={{ my: 2 }}>
                      コメント: {variant.customFields?.comment || '-'}
                    </Typography>
                  </CardContent>
                  {variant.images?.[0]?.url && (
                    <CardMedia
                      component="img"
                      sx={{
                        height: '200px',
                        mb: 3,
                        objectFit: 'contain',
                        width: '100%',
                      }}
                      image={variant.images[0].url}
                      alt={variant.title}
                    />
                  )}
                </Card>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Box>
  );
}
