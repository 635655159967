import { Box, Container, Stack, Typography } from '@mui/material';
import _ from 'lodash';
import {
  Suspense,
  useEffect,
  useCallback,
  useMemo,
  useState,
  ReactElement,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { getParentOrganizations } from '@app/adapter/organization-service';
import { ResultOrganizationCard } from '@app/components/Product/ResultOrganizationCard';
import { SearchCondition } from '@app/components/Search/SearchCondition';
import { SearchConditionSummary } from '@app/components/Search/SearchConditionSummary';
import { SearchNoResult } from '@app/components/Search/SearchNoResult';
import { SearchTypeTab } from '@app/components/Search/SearchTypeTab';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { Breadcrumb } from '@app/components/Shared/Breadcrumb';
import {
  getProductsDescription,
  HeadBlock,
} from '@app/components/Shared/HeadBlock';
import { Loading } from '@app/components/Shared/Loading';
import { ScrollThreshold } from '@app/components/Shared/ScrollThreshold';
import {
  locationsByPrefectureSelector,
  searchConditionState,
  searchResultOrganizationsState,
} from '@app/domain/catalog';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { BOTTOM_MENU_ITEMS } from '@app/static/constants';
import { theme } from '@app/theme';
import { CategoryName, CategoryType, Conditions } from '@app/types/catalog';
import { LoadableState } from '@app/types/common';
import { getSearchResultUrl } from '@app/utils/catalog';

const breadcrumbItems = [
  { label: 'トップページ', link: '/' },
  { label: '検索結果' },
];

export function Index(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const setSnackbar = useSetSnackbar();
  const [conditionState, setConditionState] =
    useRecoilState(searchConditionState);
  const [organizations, setOrganizations] = useRecoilState(
    searchResultOrganizationsState
  );
  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );
  const prefectures = useRecoilValue(locationsByPrefectureSelector);
  const { isMobile } = useDeviceType();

  const getParamToString = (value: string | null) => {
    return value ? value.split(',') : undefined;
  };

  const searchCondition = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return {
      childrenIds: getParamToString(queryParams.get('child')) || [],
      locationIds: getParamToString(queryParams.get('locations')) || [],
      organizationId: getParamToString(queryParams.get('org')) || [],
    };
  }, [location.search]);

  const headInfo = useMemo(() => {
    const prefecture =
      searchCondition.locationIds.length === 1
        ? prefectures?.find((p) => p.id === searchCondition.locationIds[0])
            ?.name
        : undefined;
    const paramLength = Array.from(
      new URLSearchParams(location.search).keys()
    ).length;

    if (paramLength > 2 || (!prefecture && paramLength > 1)) {
      return {};
    }
    return {
      description: getProductsDescription(prefecture),
    };
  }, [location.search, prefectures, searchCondition]);

  const submitSearch = useCallback(
    (data?: Conditions) => {
      const conditions = {
        ...searchCondition,
        ...data,
        maxBuildingArea:
          data?.maxBuildingArea !== undefined
            ? String(data.maxBuildingArea)
            : undefined,
        maxLandArea:
          data?.maxLandArea !== undefined
            ? String(data.maxLandArea)
            : undefined,
        maxPrice:
          data?.maxPrice !== undefined ? String(data.maxPrice) : undefined,
        minBuildingArea:
          data?.minBuildingArea !== undefined
            ? String(data.minBuildingArea)
            : undefined,
        minLandArea:
          data?.minLandArea !== undefined
            ? String(data.minLandArea)
            : undefined,
        minPrice:
          data?.minPrice !== undefined ? String(data.minPrice) : undefined,
      };
      setConditionState(null);
      globalThis.scrollTo(0, 0);
      navigate(getSearchResultUrl(conditions));
    },
    [navigate, searchCondition, setConditionState]
  );

  const tabName = useMemo(() => {
    if (location.pathname.includes('/exhibitions')) {
      return CategoryName.EXHIBITION;
    } else if (location.pathname.includes('/properties')) {
      return CategoryName.PROPERTY;
    }
    return CategoryName.EXHIBITION;
  }, [location.pathname]);

  const fetchOrganizations = useCallback(
    async (nextLink?: string) => {
      if (loadable === LoadableState.LOADING) return;
      setLoadable(LoadableState.LOADING);
      try {
        const searchParams = searchCondition;

        const options = {
          ...searchParams,
          category: CategoryType.EXHIBITION,
          expand: 'mainImage',
          nextLink,
          top: 3,
        };
        if (searchParams.organizationId.length > 0) {
          options.organizationId = searchParams.organizationId;
        }
        const result = await getParentOrganizations(options);

        setConditionState(searchCondition);
        setOrganizations({
          data: nextLink
            ? [...organizations.data, ...result.data.value]
            : result.data.value,
          nextLink: result.data['@nextLink'],
          total: result.data.total,
        });
        setLoadable(LoadableState.HAS_VALUE);
      } catch (error) {
        setLoadable(LoadableState.HAS_ERROR);
        setSnackbar(true, '展示場の取得に失敗しました');
      }
    },
    [
      loadable,
      searchCondition,
      setConditionState,
      setSnackbar,
      setOrganizations,
      organizations.data,
    ]
  );

  useEffect(() => {
    if (!conditionState) {
      setOrganizations({
        data: [],
        nextLink: '',
        scrollY: 0,
        total: 0,
      });
      void fetchOrganizations();
    } else {
      globalThis.scrollTo(0, organizations?.scrollY || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCondition]);

  const handleScrollThresholdReached = async () => {
    if (_.isEmpty(organizations.nextLink)) {
      return;
    }
    await fetchOrganizations(organizations.nextLink);
  };

  const onChangeTab = useCallback(
    (selectedTab: string) => {
      submitSearch({ ...searchCondition });
    },
    [searchCondition, submitSearch]
  );

  const handleClickProduct = useCallback(
    (id: string) => {
      setOrganizations({ ...organizations, scrollY: window.scrollY });
      navigate(`/companies/${id}`);
    },
    [navigate, organizations, setOrganizations]
  );

  return (
    <>
      <HeadBlock {...headInfo} />
      <Box data-e2e="searchResult-body">
        <Stack spacing={1} sx={{ bgcolor: 'secondary.main' }}>
          <Breadcrumb items={breadcrumbItems} />
          <Box>
            <SearchTypeTab value={tabName} onChangeTab={onChangeTab} />
            <Stack
              direction={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
            >
              <Box>
                <Suspense fallback={<Loading />}>
                  {isMobile ? (
                    <SearchConditionSummary
                      condition={searchCondition}
                      onSearch={submitSearch}
                      showPropertySearch={false}
                    />
                  ) : (
                    <SearchCondition
                      condition={searchCondition}
                      onSearch={submitSearch}
                      showPropertySearch={false}
                    />
                  )}
                </Suspense>
              </Box>
              <Box flex={2} px={1}>
                <Container sx={{ py: 2 }}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                      検索結果
                    </Typography>
                    <Typography color={theme.palette.neutral.greyDark}>
                      {organizations.total}件
                    </Typography>
                  </Stack>
                </Container>
                {loadable !== LoadableState.LOADING &&
                  organizations.data.length === 0 && <SearchNoResult />}
                <Stack
                  alignItems="center"
                  spacing={1}
                  px={1}
                  sx={{
                    bgcolor: 'secondary.main',
                    mb: 10,
                  }}
                >
                  {loadable === LoadableState.LOADING && (
                    <Loading title={`検索結果(${organizations.total})`} />
                  )}
                  {organizations.data.map((organization, index) => (
                    <ResultOrganizationCard
                      key={organization.id + index}
                      organization={organization}
                      onClick={() => handleClickProduct(organization.id)}
                    />
                  ))}
                </Stack>
                <ScrollThreshold
                  disabled={_.isEmpty(organizations.nextLink)}
                  thresholdReached={handleScrollThresholdReached}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
        <BottomMenu menuItems={BOTTOM_MENU_ITEMS} />
      </Box>
    </>
  );
}
