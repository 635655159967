import { createContext, useContext } from 'react';

import { Product, ProductLocation } from '@app/types/catalog';
import { Organization } from '@app/types/organization';
type AppContextType = {
  exhibitionPrefecture: string;
  exhibitionProducts: Product[] | null;
  handleLoadMoreExhibitionProducts: () => Promise<void>;
  handleLoadMorePropertyProducts: () => Promise<void>;
  isFetchingExhibitionProducts: boolean;
  isFetchingPropertyProducts: boolean;
  isLoadingMoreExhibitionProducts: boolean;
  isLoadingMorePropertyProducts: boolean;
  organization: Organization;
  prefectures: ProductLocation[] | null;
  productNextLink: {
    exhibition: string;
    property: string;
  };
  propertyPrefecture: string;
  propertyProducts: Product[] | null;
  setExhibitionPrefecture: React.Dispatch<React.SetStateAction<string>>;
  setPropertyPrefecture: React.Dispatch<React.SetStateAction<string>>;
};

export const CompanyExhibitionContext = createContext<
  AppContextType | undefined
>(undefined);

export const useCompanyExhibitionContext = (): AppContextType => {
  const context = useContext(CompanyExhibitionContext);
  if (!context) {
    throw new Error(
      'useCompanyExhibitionContext must be used within an CompanyExhibitionContextProvider'
    );
  }
  return context;
};
