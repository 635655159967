import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Divider,
} from '@mui/material';
import { format } from 'date-fns';
import React from 'react';

import {
  EVENT_NAME_OPTIONS,
  LAND_RIGHT_TYPE_OPTIONS,
  propertyOtherLimitationValues,
} from '@app/static/constants';
import { theme } from '@app/theme';
import {
  Product,
  ProductCompletionDateType,
  ProductCompletionSeason,
  ProductDeliveryDateType,
  ProductDeliverySeason,
  ProductLandAreaType,
  ProductRangeType,
  ProductReformExterior,
  ProductReformInterior,
  ProductSalesType,
} from '@app/types/catalog';
import { parseDateStringToDateObj } from '@app/utils/date';
import {
  convertToTsubo,
  formatAreaRange,
  formatPrice,
  formatPriceRange,
} from '@app/utils/format';

interface PropertyInfoSpProps {
  product: Product;
}

const TableCellWithStyle = ({ children }: { children: React.ReactNode }) => (
  <TableCell
    sx={{
      backgroundColor: theme.palette.neutral.sky,
      fontWeight: 'bold',
      width: '30%',
    }}
  >
    {children}
  </TableCell>
);

const TableCellWithFallback = ({
  value,
}: {
  value: string | number | undefined | null | string[];
}) => (
  <TableCell>
    {Array.isArray(value) ? value.join(', ') : value ? value : '-'}
  </TableCell>
);

export function PropertyInfoSp({ product }: PropertyInfoSpProps) {
  const getDeliverySeasonLabel = (seasonKey?: string) => {
    if (!seasonKey) return '';
    const season = Object.values(ProductDeliverySeason).find(
      (season) => season.key === seasonKey
    );
    return season ? `${season.label}` : '';
  };
  return (
    <Box sx={{ py: 2 }}>
      <Divider sx={{ my: 4 }} />
      <Typography variant="h6" fontWeight={600} sx={{ mb: 3 }}>
        物件情報
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          border: `1px solid ${theme.palette.neutral.silver}`,
          borderRadius: '8px',
          width: '100%',
        }}
      >
        <Table>
          <TableBody>
            <TableRow>
              <TableCellWithStyle>販売スケジュール</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {product.customFields.salesType && (
                  <>
                    <Typography component="span" variant="body2">
                      販売方式：
                      {
                        Object.values(ProductSalesType).find(
                          (type) => type.key === product.customFields.salesType
                        )?.label
                      }
                    </Typography>
                    <br />
                  </>
                )}
                {product.customFields.salesStartDate && (
                  <>
                    <Typography component="span" variant="body2">
                      販売開始日：
                      {format(
                        parseDateStringToDateObj(
                          product.customFields.salesStartDate
                        ),
                        'yyyy年M月d日'
                      )}
                    </Typography>
                    <br />
                  </>
                )}
                {product.customFields.salesScheduleComment && (
                  <>
                    <Typography component="span" variant="body2" color="gray">
                      {product.customFields.salesScheduleComment}
                    </Typography>
                    <br />
                  </>
                )}
                {product.customFields.lotteryDate && (
                  <>
                    <Typography component="span" variant="body2">
                      抽選日：
                      {format(
                        parseDateStringToDateObj(
                          product.customFields.lotteryDate
                        ),
                        'yyyy年M月d日'
                      )}
                    </Typography>
                    <br />
                  </>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>イベント情報</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                <>
                  {product.customFields.eventName && (
                    <>
                      <Typography component="span" variant="body2">
                        タイトル：
                        {
                          EVENT_NAME_OPTIONS.find(
                            (option) =>
                              option.value === product.customFields.eventName
                          )?.label
                        }
                      </Typography>
                      <br />
                    </>
                  )}
                  {product.customFields.eventDateSince &&
                    product.customFields.eventDateUntil && (
                      <>
                        <Typography component="span" variant="body2">
                          イベント開催期間：
                          {format(
                            parseDateStringToDateObj(
                              product.customFields.eventDateSince
                            ),
                            'yyyy年M月d日'
                          )}
                          ~
                          {format(
                            parseDateStringToDateObj(
                              product.customFields.eventDateUntil
                            ),
                            'yyyy年M月d日'
                          )}
                        </Typography>
                        <br />
                      </>
                    )}
                  {product.customFields.eventTimeSince &&
                    product.customFields.eventTimeUntil && (
                      <>
                        <Typography component="span" variant="body2">
                          イベント開催時間：
                          {`${product.customFields.eventTimeSince.replace(
                            ':',
                            '時'
                          )}分〜${product.customFields.eventTimeUntil.replace(
                            ':',
                            '時'
                          )}分`}
                        </Typography>
                        <br />
                      </>
                    )}
                  {product.customFields.eventContent && (
                    <>
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ whiteSpace: 'pre-wrap' }}
                      >
                        イベント情報 ：{product.customFields.eventContent}
                      </Typography>
                      <br />
                    </>
                  )}
                </>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>所在地</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {product.addressLine1 + product.addressLine2}
                {product.customFields.addressLine3HiddenFlag === true &&
                  product.addressLine3}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>交通</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCellWithFallback value={product.customFields.access} />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>販売戸数</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCellWithFallback
                value={
                  product.customFields.salesHouseCount
                    ? `${product.customFields.salesHouseCount}戸`
                    : '-'
                }
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>総戸数</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCellWithFallback
                value={
                  product.customFields.totalHouseCount
                    ? `${product.customFields.totalHouseCount}戸`
                    : '-'
                }
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>価格</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>
                  {formatPriceRange(
                    product.customFields.minPrice
                      ? Number(product.customFields.minPrice)
                      : undefined,
                    product.customFields.maxPrice
                      ? Number(product.customFields.maxPrice)
                      : undefined,
                    formatPrice,
                    product.customFields.priceRangeType
                  )}
                </Typography>
                <Typography>{product.customFields.priceAdditional}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>最多価格帯</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>
                  {product.customFields.mostPriceRanges &&
                  product.customFields.mostPriceRanges.length > 0
                    ? product.customFields.mostPriceRanges
                        .filter((range) => range !== '')
                        .map((range) => `${range}万円台`)
                        .join('、')
                    : '-'}
                  <Typography component="span">
                    {product.customFields.mostPriceRangeCount &&
                      `(${product.customFields.mostPriceRangeCount}戸)`}
                  </Typography>
                </Typography>
                <Typography>
                  {product.customFields.mostPriceRangeAdditional}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>私道負担・道路</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {product.customFields.minRoadWidth &&
                  `${product.customFields.minRoadWidth}m`}

                {product.customFields.roadWidthRangeType &&
                  product.customFields.maxRoadWidth && (
                    <>
                      {product.customFields.roadWidthRangeType ===
                      ProductRangeType.BETWEEN
                        ? '〜'
                        : product.customFields.roadWidthRangeType ===
                          ProductRangeType.SEPARATE
                        ? '・'
                        : ' '}
                    </>
                  )}
                {product.customFields.maxRoadWidth &&
                  `${product.customFields.maxRoadWidth}m`}
                {(product.customFields.minRoadWidth ||
                  product.customFields.maxRoadWidth) &&
                  product.customFields.roadPainting &&
                  '、'}
                {product.customFields.roadPainting}
                {product.customFields.roadNote &&
                  `、${product.customFields.roadNote}`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>諸費用</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>{product.customFields.facilityCost}</Typography>
                <Typography>{product.customFields.otherCost}</Typography>
                <Typography>{product.customFields.priceNote}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>間取りタイプ</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>
                  {product.customFields?.floorPlanRooms?.[0] && (
                    <>
                      {product.customFields.floorPlanRooms[0]}
                      {product.customFields.floorPlanTypeMin}

                      {product.customFields.floorPlanRooms.length > 1 ? (
                        <>
                          {product.customFields.floorPlanRangeType ===
                          ProductRangeType.BETWEEN
                            ? '〜'
                            : '・'}
                          {Math.max(
                            ...product.customFields.floorPlanRooms.map(Number)
                          )}
                        </>
                      ) : (
                        product.customFields.floorPlanTypeMax &&
                        product.customFields.floorPlanTypeMin !==
                          product.customFields.floorPlanTypeMax && (
                          <>
                            {product.customFields.floorPlanRangeType ===
                            ProductRangeType.BETWEEN
                              ? '〜'
                              : '・'}
                            {product.customFields.floorPlanRooms[0]}
                          </>
                        )
                      )}

                      {(product.customFields.floorPlanRooms.length > 1 ||
                        (product.customFields.floorPlanRooms[0] &&
                          product.customFields.floorPlanTypeMin !==
                            product.customFields.floorPlanTypeMax)) &&
                        product.customFields.floorPlanTypeMax}
                    </>
                  )}
                </Typography>
                <Typography color="gray">
                  {product.customFields.floorPlanTypeAdditional}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>建物面積</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {product.customFields.buildingAreaType &&
                  Object.values(ProductLandAreaType).find(
                    (type) => type.key === product.customFields.buildingAreaType
                  )?.label}
                {formatAreaRange(
                  product.customFields.minBuildingArea,
                  product.customFields.maxBuildingArea,
                  product.customFields.buildingAreaRangeType
                )}
                {product.customFields.tsuboNotationFlag && (
                  <Typography component="span" variant="body2" sx={{ ml: 1 }}>
                    （
                    {convertToTsubo(
                      Number(product.customFields.minBuildingArea)
                    )}
                    坪
                    {product.customFields.maxBuildingArea
                      ? `〜${convertToTsubo(
                          Number(product.customFields.maxBuildingArea)
                        )}坪`
                      : ' '}
                    ）
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>土地面積</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {product.customFields.landAreaType &&
                  Object.values(ProductLandAreaType).find(
                    (type) => type.key === product.customFields.landAreaType
                  )?.label}
                {formatAreaRange(
                  product.customFields.minLandArea,
                  product.customFields.maxLandArea,
                  product.customFields.landAreaRangeType
                )}
                {product.customFields.tsuboNotationFlag && (
                  <Typography component="span" variant="body2" sx={{ ml: 1 }}>
                    （{convertToTsubo(Number(product.customFields.minLandArea))}
                    坪
                    {product.customFields.maxLandArea
                      ? `〜${convertToTsubo(
                          Number(product.customFields.maxLandArea)
                        )}坪`
                      : ' '}
                    ）
                  </Typography>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>建ペイ率・容積率</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCellWithFallback value={product.customFields.areaRatio} />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>完成時期（築年月）</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {product.customFields.completionDateType &&
                  Object.values(ProductCompletionDateType).find(
                    (type) =>
                      type.key === product.customFields.completionDateType
                  )?.label}{' '}
                {format(
                  product.customFields?.completionDate
                    ? parseDateStringToDateObj(
                        product.customFields?.completionDate
                      )
                    : new Date(),
                  'yyyy年M月'
                )}
                {product.customFields.completionSeason &&
                  Object.values(ProductCompletionSeason).find(
                    (type) => type.key === product.customFields.completionSeason
                  )?.label}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>引き渡し可能時期</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>
                  {product.customFields.deliveryDateType &&
                    (() => {
                      const deliveryType = Object.values(
                        ProductDeliveryDateType
                      ).find(
                        (type) =>
                          type.key === product.customFields.deliveryDateType
                      );
                      if (
                        deliveryType?.key === 'specific' &&
                        product.customFields.deliveryDate
                      ) {
                        const formattedDate = format(
                          parseDateStringToDateObj(
                            product.customFields.deliveryDate
                          ),
                          'yyyy年M月'
                        );
                        const seasonLabel = getDeliverySeasonLabel(
                          product.customFields.deliverySeason
                        );
                        return `${formattedDate}${seasonLabel}`;
                      }

                      return deliveryType?.label || '';
                    })()}
                </Typography>
                <Typography>{product.customFields.deliveryNote}</Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCellWithStyle>土地の権利形態</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCellWithFallback
                value={
                  LAND_RIGHT_TYPE_OPTIONS.find(
                    (option) =>
                      option.value === product.customFields.landRightType
                  )?.label
                }
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>構造・工法</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCellWithFallback
                value={product.customFields.buildingSystem}
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>施工</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCellWithFallback
                value={product.customFields.constructionCompany}
              />
            </TableRow>
            <TableRow>
              <TableCellWithStyle>リフォーム</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>
                  外装：
                  {Object.values(ProductReformExterior).find(
                    (type) => type.key === product.customFields.reformExterior
                  )?.label || '-'}
                </Typography>

                <Typography>
                  内装：
                  {Object.values(ProductReformInterior).find(
                    (type) => type.key === product.customFields.reformInterior
                  )?.label || '-'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>用途地域</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {product.customFields.useDistrict &&
                  product.customFields.useDistrict
                    .filter((district) => district !== '')
                    .join('、')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>地目</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {product.customFields.landCategory ||
                product.customFields.buildingPermitReason ? (
                  <>
                    <Typography>{product.customFields.landCategory}</Typography>
                    <Typography>
                      {product.customFields.buildingPermitReason}
                    </Typography>
                  </>
                ) : (
                  '-'
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCellWithStyle>その他制限事項</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCellWithFallback
                value={
                  product.customFields.legalOtherLimitations &&
                  product.customFields.legalOtherLimitations.length > 0
                    ? product.customFields.legalOtherLimitations
                        .map(
                          (limitation: string) =>
                            propertyOtherLimitationValues[limitation] ||
                            limitation
                        )
                        .join('、')
                    : '-'
                }
              />
            </TableRow>

            <TableRow>
              <TableCellWithStyle>その他概要・特記事項</TableCellWithStyle>
            </TableRow>
            <TableRow>
              <TableCell>
                {product.customFields.facility && (
                  <Typography>設備:{product.customFields.facility}</Typography>
                )}
                {product.customFields.buildingConfirmationNumber && (
                  <Typography>
                    建築確認番号:
                    {product.customFields.buildingConfirmationNumber}
                  </Typography>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
