import {
  FormControl,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  FormHelperText,
  Stack,
  Typography,
  Select,
  MenuItem,
  Box,
} from '@mui/material';
import { getDay } from 'date-fns';
import React, { ReactElement, useCallback } from 'react';
import {
  Controller,
  Control,
  FieldErrors,
  useWatch,
  UseFormSetValue,
} from 'react-hook-form';

import { FormLabelComponent } from '@app/components/Order/FormLabelComponents';
import { DatePicker } from '@app/components/Shared/DatePicker';
import { convertNewLineToBr } from '@app/components/Shared/Helpers';
import { theme } from '@app/theme';
import {
  InquiryStepTwoQuestion,
  InquiryStepTwoFormData,
  FormType,
} from '@app/types/order';
import { OrganizationCustomFields } from '@app/types/organization';

interface ExhibitionFormFieldsProps {
  control: Control<InquiryStepTwoFormData>;
  errors: FieldErrors<InquiryStepTwoFormData>;
  isDisabled: boolean;
  organizationCustomField?: OrganizationCustomFields;
  questions: InquiryStepTwoQuestion[];
  setValue: UseFormSetValue<InquiryStepTwoFormData>;
}

export function ExhibitionFormFields({
  questions,
  control,
  errors,
  isDisabled,
  organizationCustomField,
  setValue,
}: ExhibitionFormFieldsProps): ReactElement {
  const formWatch = useWatch({ control });

  /**
   * NOTE: 選択可能な予約時間を計算する一連の処理。希望日を含むformWatchが変更されて画面が描画される際に再計算される。
   * HACK: 第3予約希望日以降も設定する要件になったら本処理は関数化したい。
   * 処理のはじまり
   */
  // 希望日の曜日を取得する
  const dayOfWeek1stReservation = getDay(
    new Date(formWatch.desiredReservationDate1?.replace(/\//g, '-') || '')
  );
  const dayOfWeek2ndReservation = getDay(
    new Date(formWatch.desiredReservationDate2?.replace(/\//g, '-') || '')
  );
  const selectedDay1st = organizationCustomField?.scheduleWeekly?.find(
    (day) => day.week === dayOfWeek1stReservation
  );
  const selectedDay2nd = organizationCustomField?.scheduleWeekly?.find(
    (day) => day.week === dayOfWeek2ndReservation
  );
  // 希望日の営業時間を取得する
  const { timeFrom: timeFromDay1st, timeTo: timeToDay1st } = selectedDay1st || {
    timeFrom: '',
    timeTo: '',
  };
  const { timeFrom: timeFromDay2nd, timeTo: timeToDay2nd } = selectedDay2nd || {
    timeFrom: '',
    timeTo: '',
  };
  const [fromHourDay1st] = timeFromDay1st.split(':');
  const [toHourDay1st] = timeToDay1st.split(':');
  const [fromHourDay2nd] = timeFromDay2nd.split(':');
  const [toHourDay2nd] = timeToDay2nd.split(':');

  /// 希望時間のセレクトオプションを作成する
  const hours = Array.from({ length: 24 }, (value, i) => i);
  const selectableHoursDay1st = hours.filter(
    (hour) => hour >= parseInt(fromHourDay1st) && hour < parseInt(toHourDay1st)
  );
  const selectableHoursDay2nd = hours.filter(
    (hour) => hour >= parseInt(fromHourDay2nd) && hour < parseInt(toHourDay2nd)
  );
  const timeHourOptionsDay1st = selectableHoursDay1st.map((hour) =>
    String(hour)
  );
  const timeHourOptionsDay2nd = selectableHoursDay2nd.map((hour) =>
    String(hour)
  );
  /*
   * 処理の終わり
   */

  const shouldDisableDate = useCallback(
    (date: Date | string): boolean => {
      if (!organizationCustomField?.scheduleActiveFlg) {
        return false;
      }
      const dateValue = typeof date === 'string' ? new Date(date) : date;
      const dayOfWeek = getDay(dateValue);
      const workingDay = organizationCustomField?.scheduleWeekly?.find(
        (day) => day.week === dayOfWeek
      );
      if (
        dateValue.toDateString() ===
          new Date(formWatch.desiredReservationDate1 || '').toDateString() ||
        dateValue.toDateString() ===
          new Date(formWatch.desiredReservationDate2 || '').toDateString()
      ) {
        return true;
      }
      return workingDay ? !workingDay.activeFlg : false;
    },
    [
      formWatch.desiredReservationDate1,
      formWatch.desiredReservationDate2,
      organizationCustomField?.scheduleActiveFlg,
      organizationCustomField?.scheduleWeekly,
    ]
  );
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  // HACK: 希望日時の処理がコンポーネント全体に多いため、切り出してもよいかもしれない。
  return (
    <Stack spacing={5}>
      <Box display="flex" gap={2}>
        <FormControl>
          {FormLabelComponent('第1予約希望日', true)}
          <Controller
            name="desiredReservationDate1"
            control={control}
            rules={{
              required: '第1予約希望日入力してください',
            }}
            render={({ field, fieldState: { error } }) => (
              <Box display="flex" gap={4}>
                <DatePicker
                  {...field}
                  inputRef={field.ref}
                  error={!!error}
                  size="small"
                  shouldDisableDate={shouldDisableDate}
                  datePicker={{
                    inputFormat: 'yyyy年M月dd日',
                    openTo: 'day',
                    value: field.value,
                    views: ['year', 'month', 'day'],
                  }}
                  readOnly
                  onChange={(value) => {
                    setValue('desiredReservationHour1', '');
                    setValue('desiredReservationMin1', '');
                    field.onChange(value);
                  }}
                  sx={{ bgcolor: 'white', width: '210px' }}
                />
              </Box>
            )}
          />
        </FormControl>
        <FormControl>
          <Controller
            name="desiredReservationHour1"
            control={control}
            rules={{
              required: '第1予約希望日の時間を入力してください',
            }}
            defaultValue=""
            render={({ field }) => (
              <Box display="flex" gap={1} alignItems="center" mt="auto">
                <Select
                  {...field}
                  value={field.value || ''}
                  defaultValue=""
                  sx={{ width: '80px' }}
                  onChange={(e) => field.onChange(e.target.value)}
                >
                  <MenuItem value="">
                    <em>未選択</em>
                  </MenuItem>
                  {timeHourOptionsDay1st.map((hour) => (
                    <MenuItem key={hour} value={hour}>
                      {hour}
                    </MenuItem>
                  ))}
                </Select>
                <Typography display="inline-block">時</Typography>
              </Box>
            )}
          />
        </FormControl>
        <FormControl>
          <Controller
            name="desiredReservationMin1"
            control={control}
            rules={{
              required: '第1予約希望日の時間を入力してください',
            }}
            defaultValue=""
            render={({ field }) => (
              <Box display="flex" gap={1} alignItems="center" mt="auto">
                <Select
                  {...field}
                  value={field.value || ''}
                  defaultValue=""
                  sx={{ width: '80px' }}
                  onChange={(e) => field.onChange(e.target.value)}
                >
                  <MenuItem value="">
                    <em>未選択</em>
                  </MenuItem>
                  {['00', '30'].map((min) => (
                    <MenuItem key={min} value={min}>
                      {min}
                    </MenuItem>
                  ))}
                </Select>
                <Typography display="inline-block">分</Typography>
              </Box>
            )}
          />
        </FormControl>
      </Box>
      <Box display="flex" gap={2}>
        <FormControl>
          {FormLabelComponent('第2予約希望日', true)}
          <Controller
            name="desiredReservationDate2"
            control={control}
            rules={{
              required: '第2予約希望日入力してください',
            }}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                shouldDisableDate={shouldDisableDate}
                inputRef={field.ref}
                error={!!error}
                size="medium"
                datePicker={{
                  inputFormat: 'yyyy年M月dd日',
                  openTo: 'day',
                  value: field.value,
                  views: ['year', 'month', 'day'],
                }}
                readOnly
                onChange={(value) => {
                  setValue('desiredReservationHour2', '');
                  setValue('desiredReservationMin2', '');
                  field.onChange(value);
                }}
                sx={{ bgcolor: 'white', width: '210px' }}
              />
            )}
          />
        </FormControl>
        <Box display="flex" gap={2}>
          <FormControl>
            <Controller
              name="desiredReservationHour2"
              control={control}
              rules={{
                required: '第2予約希望日の時間を入力してください',
              }}
              defaultValue=""
              render={({ field }) => (
                <Box display="flex" gap={1} alignItems="center" mt="auto">
                  <Select
                    {...field}
                    value={field.value || ''}
                    defaultValue=""
                    sx={{ width: '80px' }}
                    onChange={(e) => field.onChange(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>未選択</em>
                    </MenuItem>
                    {timeHourOptionsDay2nd.map((hour) => (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography display="inline-block">時</Typography>
                </Box>
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name="desiredReservationMin2"
              control={control}
              rules={{
                required: '第2予約希望日の時間を入力してください',
              }}
              defaultValue=""
              render={({ field }) => (
                <Box display="flex" gap={1} alignItems="center" mt="auto">
                  <Select
                    {...field}
                    value={field.value || ''}
                    defaultValue=""
                    sx={{ width: '80px' }}
                    onChange={(e) => field.onChange(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>未選択</em>
                    </MenuItem>
                    {['00', '30'].map((min) => (
                      <MenuItem key={min} value={min}>
                        {min}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography display="inline-block">分</Typography>
                </Box>
              )}
            />
          </FormControl>
        </Box>
      </Box>
      {questions.map((question, index) => (
        <FormControl fullWidth key={index} error={!!errors[question.title]}>
          {FormLabelComponent(question.title, question.required)}
          <Controller
            name={question.title}
            control={control}
            rules={{
              required: question.required
                ? `${question.title}は必須です`
                : false,
              validate: (value) => {
                if (question.required) {
                  if (Array.isArray(value)) {
                    return value.length > 0 || '無効な入力です';
                  }
                  return (
                    (typeof value === 'string' && value.trim() !== '') ||
                    '無効な入力です'
                  );
                }
                return true;
              },
            }}
            render={({ field }) => (
              <>
                {question.formType === FormType.TEXT && (
                  <>
                    <TextField
                      {...field}
                      type="text"
                      sx={{ mt: 1 }}
                      value={field.value || ''}
                      disabled={isDisabled}
                      error={!!errors[question.title]}
                      helperText={
                        errors[question.title]
                          ? (errors[question.title] as { message: string })
                              .message
                          : ''
                      }
                    />
                    {question.otherFlg && (
                      <Controller
                        name={`${question.title} その他`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            type="text"
                            sx={{ mt: 1 }}
                            value={field.value || ''}
                            placeholder="その他"
                            disabled={isDisabled}
                          />
                        )}
                      />
                    )}
                    {question.subText && (
                      <Typography
                        sx={{
                          backgroundColor: theme.palette.grey[100],
                          border: `1px solid ${theme.palette.grey[400]}`,
                          borderRadius: 1,
                          fontWeight: 'bold',
                          mt: 1,
                          p: 1.5,
                        }}
                        variant="body1"
                      >
                        {convertNewLineToBr(question.subText)}
                      </Typography>
                    )}
                  </>
                )}
                {question.formType === FormType.RADIO && (
                  <>
                    <RadioGroup {...field} value={field.value || ''}>
                      {question.selections.map((option, index) => (
                        <FormControlLabel
                          key={index}
                          value={option}
                          control={<Radio />}
                          label={option}
                          disabled={isDisabled}
                        />
                      ))}
                      {question.otherFlg && (
                        <Controller
                          name={`${question.title} その他`}
                          control={control}
                          render={({ field: otherField }) => (
                            <>
                              <FormControlLabel
                                value={otherField.value || ''}
                                control={<Radio />}
                                label={
                                  <TextField
                                    {...otherField}
                                    type="text"
                                    sx={{ mt: 1 }}
                                    value={otherField.value || ''}
                                    placeholder="その他"
                                    disabled={isDisabled}
                                    onKeyDown={onKeyDown}
                                    onBlur={() =>
                                      field.onChange(otherField.value)
                                    }
                                  />
                                }
                                disabled={isDisabled}
                              />
                            </>
                          )}
                        />
                      )}
                    </RadioGroup>
                    {!!errors[question.title] && (
                      <FormHelperText error>
                        {
                          (errors[question.title] as { message: string })
                            .message
                        }
                      </FormHelperText>
                    )}
                    {question.subText && (
                      <Typography
                        sx={{
                          backgroundColor: theme.palette.grey[100],
                          border: `1px solid ${theme.palette.grey[400]}`,
                          borderRadius: 1,
                          fontWeight: 'bold',
                          mt: 1,
                          p: 1.5,
                        }}
                        variant="body1"
                      >
                        {convertNewLineToBr(question.subText)}
                      </Typography>
                    )}
                  </>
                )}
                {question.formType === FormType.CHECKBOX && (
                  <>
                    {question.selections.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Controller
                            name={question.title}
                            control={control}
                            render={({ field }) => {
                              const valueArray = Array.isArray(field.value)
                                ? field.value
                                : [];
                              return (
                                <Checkbox
                                  {...field}
                                  value={option}
                                  checked={valueArray.includes(option)}
                                  onChange={(e) => {
                                    const newValue = e.target.checked
                                      ? [...valueArray, option]
                                      : valueArray.filter(
                                          (value: string) => value !== option
                                        );
                                    field.onChange(newValue);
                                  }}
                                  disabled={isDisabled}
                                />
                              );
                            }}
                          />
                        }
                        label={option}
                      />
                    ))}
                    {question.otherFlg && (
                      <Controller
                        name={`${question.title} その他`}
                        control={control}
                        rules={{
                          validate: () => {
                            if (
                              Array.isArray(field.value) &&
                              question.required
                            ) {
                              return field.value.some((value) => !value)
                                ? '無効な入力です'
                                : true;
                            }
                            return true;
                          },
                        }}
                        render={({ field: otherField }) => {
                          const valueArray = Array.isArray(field.value)
                            ? field.value
                            : [];
                          const handleTextFieldChange = (
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const newValue = e.target.value;
                            const updatedValueArray = valueArray.filter(
                              (value: string) => value !== otherField.value
                            );
                            otherField.onChange(newValue);
                            field.onChange([...updatedValueArray, newValue]);
                          };

                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  {...field}
                                  value={otherField.value || ''}
                                  checked={valueArray.includes(
                                    otherField.value as string
                                  )}
                                  onChange={(e) => {
                                    const newValue = e.target.checked
                                      ? [...valueArray, otherField.value]
                                      : valueArray.filter(
                                          (value: string) =>
                                            value !== otherField.value
                                        );
                                    field.onChange(newValue);
                                  }}
                                  disabled={isDisabled}
                                />
                              }
                              label={
                                <TextField
                                  {...otherField}
                                  type="text"
                                  sx={{ mt: 1 }}
                                  value={otherField.value || ''}
                                  placeholder="その他"
                                  onChange={handleTextFieldChange}
                                  disabled={isDisabled}
                                  onKeyDown={onKeyDown}
                                />
                              }
                              disabled={isDisabled}
                            />
                          );
                        }}
                      />
                    )}
                    {!!errors[question.title] && (
                      <FormHelperText error>
                        {
                          (errors[question.title] as { message: string })
                            .message
                        }
                      </FormHelperText>
                    )}
                    {question.subText && (
                      <Typography
                        sx={{
                          backgroundColor: theme.palette.grey[100],
                          border: `1px solid ${theme.palette.grey[400]}`,
                          borderRadius: 1,
                          fontWeight: 'bold',
                          mt: 1,
                          p: 1.5,
                        }}
                        variant="body1"
                      >
                        {convertNewLineToBr(question.subText)}
                      </Typography>
                    )}
                  </>
                )}
                {question.formType === FormType.PULLDOWN && (
                  <>
                    <Controller
                      name={question.title}
                      control={control}
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            value={field.value || ''}
                            displayEmpty
                            disabled={isDisabled}
                            error={!!errors[question.title]}
                          >
                            <MenuItem value="">
                              <em>選択してください</em>
                            </MenuItem>
                            {question.selections.map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        );
                      }}
                    />
                    {!!errors[question.title] && (
                      <FormHelperText error>
                        {
                          (errors[question.title] as { message: string })
                            .message
                        }
                      </FormHelperText>
                    )}
                    {question.otherFlg && (
                      <Controller
                        name={`${question.title} その他`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            sx={{ mt: 1 }}
                            type="text"
                            value={field.value || ''}
                            placeholder="その他"
                            disabled={isDisabled}
                          />
                        )}
                      />
                    )}
                    {question.subText && (
                      <Typography
                        sx={{
                          backgroundColor: theme.palette.grey[100],
                          border: `1px solid ${theme.palette.grey[400]}`,
                          borderRadius: 1,
                          fontWeight: 'bold',
                          mt: 1,
                          p: 1.5,
                        }}
                        variant="body1"
                      >
                        {convertNewLineToBr(question.subText)}
                      </Typography>
                    )}
                  </>
                )}
              </>
            )}
          />
        </FormControl>
      ))}
    </Stack>
  );
}
