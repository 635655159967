import {
  Attachment,
  Attribute,
  AttributeGroupName,
  AttributeGroupNameKey,
  Product,
  SearchCondition,
} from '@app/types/catalog';

export const getSearchResultUrl = (condition?: SearchCondition | null) => {
  const options = [];
  if (condition?.categoryId) {
    options.push(`category=${condition.categoryId}`);
  }
  if (
    (condition?.variant?.min || condition?.variant?.max) &&
    condition?.variant?.type
  ) {
    options.push(`variantType=${condition.variant.type}`);
    if (condition.variant.max) {
      options.push(`variantMax=${condition.variant.max}`);
    }
    if (condition.variant.min) {
      options.push(`variantMin=${condition.variant.min}`);
    }
  }
  if (condition?.locationIds && condition?.locationIds.length > 0) {
    options.push(`locations=${condition.locationIds.join(',')}`);
  }
  if (condition?.organizationId && condition.organizationId.length > 0) {
    options.push(`org=${condition.organizationId}`);
  }
  if (condition?.minPrice) {
    options.push(`minPrice=${condition.minPrice}`);
  }
  if (condition?.maxPrice) {
    options.push(`maxPrice=${condition.maxPrice}`);
  }
  if (condition?.minLandArea) {
    options.push(`minLandArea=${condition.minLandArea}`);
  }
  if (condition?.maxLandArea) {
    options.push(`maxLandArea=${condition.maxLandArea}`);
  }
  if (condition?.minBuildingArea) {
    options.push(`minBuildingArea=${condition.minBuildingArea}`);
  }
  if (condition?.maxBuildingArea) {
    options.push(`maxBuildingArea=${condition.maxBuildingArea}`);
  }
  if (condition?.access) {
    options.push(`access=${condition.access}`);
  }
  if (condition?.floorPlanRooms && condition?.floorPlanRooms.length > 0) {
    options.push(`floorPlanRooms=${condition.floorPlanRooms.join(',')}`);
  }
  if (condition?.orderBy) {
    options.push(`orderBy=${condition.orderBy}`);
  }

  const searchParams = options.join('&');

  const isPropertySearch = window.location.pathname.includes('/properties');
  return isPropertySearch
    ? `/properties?${searchParams}`
    : `/exhibitions?${searchParams}`;
};

export const getAttributesByGroupName = (
  attributes: Attribute[],
  groupName: AttributeGroupNameKey
) => {
  return attributes.filter((a) => a.groupName === groupName);
};

export const getProductClinicalDeptLabel = (
  attributes: Attribute[],
  product: Product
) => {
  return getAttributesByGroupName(
    attributes,
    AttributeGroupName.CLINICAL_DEPARTMENT
  )
    .filter((item) => {
      return product.attributes?.some(
        (i) => (i.id || i.attributeId) === item.id
      );
    })
    .map((i) => i.items[0].value)
    .join('・');
};

export const getAttributesGroupByName = (
  attributes: Attribute[],
  groupName: AttributeGroupNameKey
): Attribute[] => {
  return attributes
    .filter((a) => a.groupName === groupName)
    .reduce((items: Attribute[], item: Attribute) => {
      const foundGroup = items.find((i) => i.name === item.name);
      if (foundGroup) {
        foundGroup.children?.push(item);
      } else {
        items.push({
          ...item,
          children: [item],
        });
      }
      return items;
    }, []);
};

export function replaceProductImages(product: Product): Product {
  return {
    ...product,
    images: product.imageIds
      ?.map((id: string) =>
        product.images?.find((image: Attachment) => image.id === id)
      )
      .filter((i) => !!i) as Attachment[],
  };
}
