import { Box, Container, Grid, Typography } from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';

import { OrganizationInfoSp } from './OrganizationInfoSp';
import { PropertyInfoSp } from './PropertyInfoSp';

import {
  getCategories,
  getOrganizationProducts,
} from '@app/adapter/catalog-service';
import {
  InquiryCardsHorizontal,
  InquiryCardsVertical,
} from '@app/components/Product/InquiryCards';
import { OrganizationInfo } from '@app/components/Product/OrganizationInfo';
import { ProductCardList } from '@app/components/Product/ProductCardList';
import { ProductDetails } from '@app/components/Product/ProductDetails';
import { PropertyFloorPlan } from '@app/components/Product/PropertyFloorPlan';
import { PropertyInfo } from '@app/components/Product/PropertyInfo';
import { PropertySalesInfo } from '@app/components/Product/PropertySalesInfo';
import { PropertyVariantSkuDetails } from '@app/components/Product/PropertyVariantSkuDetails';
import { Loading } from '@app/components/Shared/Loading';
import { Map } from '@app/components/Shared/Map';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { CategoryType, Product, ProductVariantSku } from '@app/types/catalog';
import { Organization } from '@app/types/organization';

interface PropertyDetailInfoProps {
  product: Product;
}

export function PropertyDetailInfo({
  product,
}: PropertyDetailInfoProps): ReactElement {
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [resultProducts, setResultProducts] = useState<Product[]>([]);
  const { isDesktop, isMobile } = useDeviceType();

  const fetchCategoryId = useCallback(async () => {
    try {
      const response = await getCategories();
      const propertyCategory = response.data.value.find(
        (category) => category.name === CategoryType.PROPERTY
      );
      setCategoryId(propertyCategory ? propertyCategory.id : null);
    } catch (error) {
      console.error('Failed to fetch categories', error);
    }
  }, []);

  const fetchProducts = useCallback(async () => {
    if (!categoryId) return;
    try {
      const response = await getOrganizationProducts(
        product.organizationId,
        categoryId
      );
      setResultProducts(response.data.value);
    } catch (error) {
      console.error('Failed to fetch organization products', error);
    }
  }, [product.organizationId, categoryId]);

  useEffect(() => {
    void fetchCategoryId();
  }, [fetchCategoryId]);

  useEffect(() => {
    void fetchProducts();
  }, [fetchProducts]);

  if (!product) {
    return <Loading />;
  }

  const floorPlanVariants = product.variants.filter(
    (variant) => variant.sku === ProductVariantSku.FLOOR_PLAN
  );

  const relevantVariants = product.variants.filter(
    (variant) =>
      variant.sku === ProductVariantSku.EXTERIOR ||
      variant.sku === ProductVariantSku.AROUND ||
      variant.sku === ProductVariantSku.EQUIPMENT ||
      variant.sku === ProductVariantSku.INTERIOR
  );

  return (
    <Box maxWidth="100vw">
      <Container sx={{ pb: 10, pt: 3, px: 4 }} maxWidth="xl" disableGutters>
        <Grid container spacing={3}>
          <Grid item xs={12} md={9} sx={{ width: '100%' }}>
            <ProductDetails product={product} />
            <PropertySalesInfo product={product} />
            <PropertyFloorPlan
              productId={product.id}
              organizationId={product.organizationId}
              variants={floorPlanVariants}
              parentOrganizationId={
                (product.organization as Organization)?.parentId || ''
              }
            />
            {product.organizationId && (
              <InquiryCardsHorizontal
                type={CategoryType.PROPERTY}
                isExhibition={false}
                organizationId={product.organizationId}
                parentOrganizationId={
                  (product.organization as Organization)?.parentId || ''
                }
              />
            )}
            {relevantVariants.length > 0 && (
              <>
                <PropertyVariantSkuDetails variants={relevantVariants} />
                {product.organizationId && (
                  <InquiryCardsHorizontal
                    type={CategoryType.PROPERTY}
                    isExhibition={false}
                    parentOrganizationId={
                      (product.organization as Organization)?.parentId || ''
                    }
                  />
                )}
              </>
            )}
            <Map
              address={[
                product.addressLine1,
                product.addressLine2,
                product.customFields.addressLine3HiddenFlag
                  ? product.addressLine3
                  : '',
              ].join(' ')}
            />
            {isMobile ? (
              <>
                <PropertyInfoSp product={product} />
                <OrganizationInfoSp product={product} />
              </>
            ) : (
              <>
                <PropertyInfo product={product} />
                <OrganizationInfo product={product} />
              </>
            )}
            {isMobile && (
              <Box mt={2}>
                <InquiryCardsVertical
                  organizationId={product.organizationId}
                  isExhibition={false}
                  parentOrganizationId={
                    (product.organization as Organization)?.parentId || ''
                  }
                />
              </Box>
            )}
            {resultProducts.length > 0 && (
              <>
                <Grid sx={{ mb: isMobile ? 0 : 12, mt: 5 }}>
                  <Typography
                    variant="h6"
                    fontWeight={600}
                    textAlign="left"
                    sx={{ mx: isMobile ? 0 : 3 }}
                  >
                    おすすめの分譲情報
                  </Typography>
                  <ProductCardList
                    products={resultProducts}
                    initialVisibleItems={6}
                    isProperty={true}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {isDesktop && (
            <Grid
              item
              md={3}
              sx={{
                alignSelf: 'flex-start',
                display: 'flex',
                justifyContent: 'center',
                mt: 7,
                position: 'sticky',
                top: '100px',
              }}
            >
              <InquiryCardsVertical
                organizationId={product.organizationId}
                isExhibition={false}
                parentOrganizationId={
                  (product.organization as Organization)?.parentId || ''
                }
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
}
