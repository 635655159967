import { Box } from '@mui/material';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { getProducts } from '@app/adapter/catalog-service';
import { getParentOrganizations } from '@app/adapter/organization-service';
import { SearchCondition } from '@app/components/Search/SearchCondition';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { Breadcrumb } from '@app/components/Shared/Breadcrumb';
import {
  searchConditionState,
  searchResultOrganizationsState,
  searchResultProductsState,
} from '@app/domain/catalog';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import { BOTTOM_MENU_ITEMS } from '@app/static/constants';
import { CategoryType, Conditions } from '@app/types/catalog';
import { LoadableState } from '@app/types/common';
import { getSearchResultUrl } from '@app/utils/catalog';

// Hack: views/Product/Exhibitions/index.tsx および views/Product/Properties/index.tsx と共通処理が多いため、余力がある際はリファクタリングを行いたい。

/**
 * 展示場検索カードコンポーネントを返す
 * @returns 展示場検索カード
 */
function SearchExhibition(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const setSnackbar = useSetSnackbar();
  const [conditionState, setConditionState] =
    useRecoilState(searchConditionState);
  const [organizations, setOrganizations] = useRecoilState(
    searchResultOrganizationsState
  );
  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );

  const getParamToString = (value: string | null) => {
    return value ? value.split(',') : undefined;
  };

  const searchCondition = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return {
      childrenIds: getParamToString(queryParams.get('child')) || [],
      locationIds: getParamToString(queryParams.get('locations')) || [],
      organizationId: getParamToString(queryParams.get('org')) || [],
    };
  }, [location.search]);

  const submitSearch = useCallback(
    (data?: Conditions) => {
      const conditions = {
        ...searchCondition,
        ...data,
        maxBuildingArea:
          data?.maxBuildingArea !== undefined
            ? String(data.maxBuildingArea)
            : undefined,
        maxLandArea:
          data?.maxLandArea !== undefined
            ? String(data.maxLandArea)
            : undefined,
        maxPrice:
          data?.maxPrice !== undefined ? String(data.maxPrice) : undefined,
        minBuildingArea:
          data?.minBuildingArea !== undefined
            ? String(data.minBuildingArea)
            : undefined,
        minLandArea:
          data?.minLandArea !== undefined
            ? String(data.minLandArea)
            : undefined,
        minPrice:
          data?.minPrice !== undefined ? String(data.minPrice) : undefined,
      };
      setConditionState(null);
      globalThis.scrollTo(0, 0);
      navigate(getSearchResultUrl(conditions));
    },
    [navigate, searchCondition, setConditionState]
  );

  const fetchOrganizations = useCallback(
    async (nextLink?: string) => {
      if (loadable === LoadableState.LOADING) return;
      setLoadable(LoadableState.LOADING);
      try {
        const searchParams = searchCondition;

        const options = {
          ...searchParams,
          category: CategoryType.EXHIBITION,
          nextLink,
          top: 3,
        };
        if (searchParams.organizationId.length > 0) {
          options.organizationId = searchParams.organizationId;
        }
        const result = await getParentOrganizations(options);

        setConditionState(searchCondition);
        setOrganizations({
          data: nextLink
            ? [...organizations.data, ...result.data.value]
            : result.data.value,
          nextLink: result.data['@nextLink'],
          total: result.data.total,
        });
        setLoadable(LoadableState.HAS_VALUE);
      } catch (error) {
        setLoadable(LoadableState.HAS_ERROR);
        setSnackbar(true, '展示場の取得に失敗しました');
      }
    },
    [
      loadable,
      searchCondition,
      setConditionState,
      setSnackbar,
      setOrganizations,
      organizations.data,
    ]
  );

  useEffect(() => {
    if (!conditionState) {
      setOrganizations({
        data: [],
        nextLink: '',
        scrollY: 0,
        total: 0,
      });
      void fetchOrganizations();
    } else {
      globalThis.scrollTo(0, organizations?.scrollY || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCondition]);

  return (
    <SearchCondition
      condition={searchCondition}
      onSearch={submitSearch}
      showPropertySearch={false}
    />
  );
}

/**
 * 分譲検索カードコンポーネントを返す
 * @returns 分譲検索カード
 */
function SearchProperty(): ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const setSnackbar = useSetSnackbar();
  const [conditionState, setConditionState] =
    useRecoilState(searchConditionState);
  const [resultProducts, setResultProducts] = useRecoilState(
    searchResultProductsState
  );
  const [loadable, setLoadable] = useState<LoadableState>(
    LoadableState.HAS_VALUE
  );

  const getParamToString = (value: string | null) => {
    return value ? value.split(',') : undefined;
  };

  const searchCondition = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);

    const floorPlanRoomsString = queryParams.get('floorPlanRooms');
    const floorPlanRooms = floorPlanRoomsString
      ? floorPlanRoomsString.split(',').map((value) => parseInt(value, 10))
      : [];

    return {
      access: queryParams.get('access') || '',
      childrenIds: getParamToString(queryParams.get('child')) || [],
      floorPlanRooms,
      locationIds: getParamToString(queryParams.get('locations')) || [],
      maxBuildingArea: queryParams.get('maxBuildingArea') || '',
      maxLandArea: queryParams.get('maxLandArea') || '',
      maxPrice: queryParams.get('maxPrice') || '',
      minBuildingArea: queryParams.get('minBuildingArea') || '',
      minLandArea: queryParams.get('minLandArea') || '',
      minPrice: queryParams.get('minPrice') || '',
      organizationId: getParamToString(queryParams.get('org')) || [],
    };
  }, [location.search]);

  const submitSearch = useCallback(
    (data?: Conditions) => {
      const conditions = {
        ...searchCondition,
        ...data,
        maxBuildingArea:
          data?.maxBuildingArea !== undefined
            ? String(data.maxBuildingArea)
            : undefined,
        maxLandArea:
          data?.maxLandArea !== undefined
            ? String(data.maxLandArea)
            : undefined,
        maxPrice:
          data?.maxPrice !== undefined ? String(data.maxPrice) : undefined,
        minBuildingArea:
          data?.minBuildingArea !== undefined
            ? String(data.minBuildingArea)
            : undefined,
        minLandArea:
          data?.minLandArea !== undefined
            ? String(data.minLandArea)
            : undefined,
        minPrice:
          data?.minPrice !== undefined ? String(data.minPrice) : undefined,
      };
      setConditionState(null);
      globalThis.scrollTo(0, 0);
      navigate(getSearchResultUrl(conditions));
    },
    [navigate, searchCondition, setConditionState]
  );

  const fetchProducts = useCallback(
    async (nextLink?: string) => {
      if (loadable === LoadableState.LOADING) return;
      setLoadable(LoadableState.LOADING);
      try {
        const searchParams = searchCondition;

        const options = {
          ...searchCondition,
          category: CategoryType.PROPERTY,
          childrenIds: searchParams.childrenIds,
          expand: 'attributes,variants,images,organization,locations',
          maxBuildingArea: searchParams.maxBuildingArea
            ? Number(searchParams.maxBuildingArea)
            : undefined,
          maxLandArea: searchParams.maxLandArea
            ? Number(searchParams.maxLandArea)
            : undefined,
          maxPrice: searchParams.maxPrice
            ? Number(searchParams.maxPrice)
            : undefined,
          minBuildingArea: searchParams.minBuildingArea
            ? Number(searchParams.minBuildingArea)
            : undefined,
          minLandArea: searchParams.minLandArea
            ? Number(searchParams.minLandArea)
            : undefined,
          minPrice: searchParams.minPrice
            ? Number(searchParams.minPrice)
            : undefined,
          nextLink,
        };

        const result = await getProducts(options);

        setConditionState(searchCondition);
        setResultProducts({
          data: nextLink
            ? [...resultProducts.data, ...result.data.value]
            : result.data.value,
          nextLink: result.data['@nextLink'],
          total: result.data.total,
        });
        setLoadable(LoadableState.HAS_VALUE);
      } catch (error) {
        setLoadable(LoadableState.HAS_ERROR);
        setSnackbar(true, '求人の取得に失敗しました');
      }
    },
    [
      loadable,
      searchCondition,
      resultProducts.data,
      setConditionState,
      setSnackbar,
      setResultProducts,
    ]
  );

  useEffect(() => {
    if (!conditionState) {
      setResultProducts({
        data: [],
        nextLink: '',
        scrollY: 0,
        total: 0,
      });
      void fetchProducts();
    } else {
      globalThis.scrollTo(0, resultProducts?.scrollY || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCondition]);

  return (
    <SearchCondition
      condition={{
        ...searchCondition,
        maxBuildingArea: searchCondition.maxBuildingArea
          ? Number(searchCondition.maxBuildingArea)
          : undefined,
        maxLandArea: searchCondition.maxLandArea
          ? Number(searchCondition.maxLandArea)
          : undefined,
        maxPrice: searchCondition.maxPrice
          ? Number(searchCondition.maxPrice)
          : undefined,
        minBuildingArea: searchCondition.minBuildingArea
          ? Number(searchCondition.minBuildingArea)
          : undefined,

        minLandArea: searchCondition.minLandArea
          ? Number(searchCondition.minLandArea)
          : undefined,
        minPrice: searchCondition.minPrice
          ? Number(searchCondition.minPrice)
          : undefined,
      }}
      onSearch={submitSearch}
      showPropertySearch={true}
    />
  );
}

/**
 * カテゴリーに基づき検索カードコンポーネントを返す
 * @returns 検索カード
 */
export function Search(): ReactElement {
  /**
   * 現在のURL
   */
  const location = useLocation();
  /**
   * クエリパラメーター
   */
  const queryParams = new URLSearchParams(location.search);
  /**
   * パンくずリストのアイテム
   */
  const breadcrumbItems = [
    { label: 'トップページ', link: `/` },
    {
      label: '検索結果',
      link: `${location.pathname.replace(
        '/search',
        ''
      )}?${queryParams.toString()}`,
    },
    { label: '検索条件' },
  ];

  return (
    <Box>
      <Box display="flex" flexDirection="column" gap={4} mb={4}>
        <Breadcrumb items={breadcrumbItems} />
        {location.pathname.includes('/exhibitions') ? (
          <SearchExhibition />
        ) : (
          <SearchProperty />
        )}
      </Box>
      <BottomMenu menuItems={BOTTOM_MENU_ITEMS} />
    </Box>
  );
}
