import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getLocationTree, getProducts } from '@app/adapter/catalog-service';
import { getOrganization } from '@app/adapter/organization-service';
import { OrganizationInfo } from '@app/components/Company/OrganizationInfo';
import { BottomMenu } from '@app/components/Shared/BottomMenu';
import { Breadcrumb } from '@app/components/Shared/Breadcrumb';
import { HeadBlock } from '@app/components/Shared/HeadBlock';
import { LoadingSpinner } from '@app/components/Shared/LoadingSpinner';
import { CompanyExhibitionContext } from '@app/hooks/companyExhibitionContext';
import { useSetSnackbar } from '@app/hooks/useSetSnackbar';
import {
  BOTTOM_MENU_ITEMS,
  ORGANIZATION_STRUCTURE_TYPE,
} from '@app/static/constants';
import {
  CategoryType,
  Product,
  ProductLocation,
  ProductLocationType,
} from '@app/types/catalog';
import { Organization, OrganizationStatus } from '@app/types/organization';
import { isError } from '@app/utils/error';

const breadcrumbItems = [
  { label: 'トップページ', link: '/' },
  { label: '企業詳細' },
];

export function Index(): ReactElement {
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [exhibitionProducts, setExhibitionProducts] = useState<Product[] | []>(
    []
  );
  const [propertyProducts, setPropertyProducts] = useState<Product[] | []>([]);
  const [prefectures, setPrefectures] = useState<ProductLocation[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMoreExhibitionProducts, setIsLoadingMoreExhibitionProducts] =
    useState(false);
  const [isLoadingMorePropertyProducts, setIsLoadingMorePropertyProducts] =
    useState(false);
  const [isFetchingPropertyProducts, setIsFetchingPropertyProducts] =
    useState(false);
  const [isFetchingExhibitionProducts, setIsFetchingExhibitionProducts] =
    useState(false);
  const setSnackbar = useSetSnackbar();
  const productLimit = 6;
  const [productNextLink, setProductNextLink] = useState<{
    exhibition: string;
    property: string;
  }>({
    exhibition: '',
    property: '',
  });
  const [exhibitionPrefecture, setExhibitionPrefecture] = useState<string>('');
  const [propertyPrefecture, setPropertyPrefecture] = useState<string>('');

  const childrenOrganizationIds = useMemo(() => {
    return (
      organization?.children
        ?.filter((item) => item.status !== OrganizationStatus.CANCELLED)
        .map((child) => child.id) || []
    );
  }, [organization]);

  const fetchOrganization = useCallback(async () => {
    if (!organizationId) return;
    try {
      const response = await getOrganization(organizationId, {
        expand: `${ORGANIZATION_STRUCTURE_TYPE.CHILDREN},mainImage,strengthImage`,
      });
      setOrganization(response.data);
    } catch (error) {
      if (isError(error)) {
        setSnackbar(true, error.message);
      }
    }
  }, [organizationId, setSnackbar]);

  useEffect(() => {
    if (childrenOrganizationIds.length > 0) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('child', childrenOrganizationIds.join(','));
      navigate(`${window.location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  }, [childrenOrganizationIds, navigate]);

  const fetchExhibitionProducts = useCallback(
    async (nextLink?: string) => {
      if (!organization || childrenOrganizationIds.length === 0) return;
      try {
        const response = await getProducts({
          category: CategoryType.EXHIBITION,
          limit: productLimit,
          nextLink,
          organizationId: childrenOrganizationIds,
          prefecture: exhibitionPrefecture,
        });
        setProductNextLink((prev) => ({
          ...prev,
          exhibition: response.data['@nextLink'],
        }));
        setExhibitionProducts((prev) =>
          nextLink ? [...prev, ...response.data.value] : response.data.value
        );
      } catch (error) {
        if (isError(error)) {
          setSnackbar(true, error.message);
        }
      }
    },
    [childrenOrganizationIds, exhibitionPrefecture, organization, setSnackbar]
  );

  const fetchPropertyProducts = useCallback(
    async (nextLink?: string) => {
      if (!organization || childrenOrganizationIds.length === 0) return;
      try {
        const response = await getProducts({
          category: CategoryType.PROPERTY,
          limit: productLimit,
          nextLink,
          organizationId: childrenOrganizationIds,
          prefecture: propertyPrefecture,
        });
        setProductNextLink((prev) => ({
          ...prev,
          property: response.data['@nextLink'],
        }));
        setPropertyProducts((prev) =>
          nextLink ? [...prev, ...response.data.value] : response.data.value
        );
      } catch (error) {
        if (isError(error)) {
          setSnackbar(true, error.message);
        }
      }
    },
    [childrenOrganizationIds, organization, propertyPrefecture, setSnackbar]
  );

  const fetchLocations = useCallback(async () => {
    const response = await getLocationTree({
      type: ProductLocationType.PREFECTURE,
    });

    const prefectures = response.data.value
      .map((item) => item.children || [])
      .flat();
    setPrefectures(prefectures);
  }, []);

  const initData = useCallback(async () => {
    setIsLoading(true);
    await fetchOrganization();
    await fetchLocations();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMoreExhibitionProducts = useCallback(async () => {
    setIsLoadingMoreExhibitionProducts(true);
    await fetchExhibitionProducts(productNextLink.exhibition);
    setIsLoadingMoreExhibitionProducts(false);
  }, [fetchExhibitionProducts, productNextLink.exhibition]);

  const handleLoadMorePropertyProducts = useCallback(async () => {
    setIsLoadingMorePropertyProducts(true);
    await fetchPropertyProducts(productNextLink.property);
    setIsLoadingMorePropertyProducts(false);
  }, [fetchPropertyProducts, productNextLink.property]);

  const handleFetchPropertyProducts = useCallback(async () => {
    setIsFetchingPropertyProducts(true);
    await fetchPropertyProducts();
    setIsFetchingPropertyProducts(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyPrefecture, organization]);

  const handleFetchExhibitionProducts = useCallback(async () => {
    setIsFetchingExhibitionProducts(true);
    await fetchExhibitionProducts();
    setIsFetchingExhibitionProducts(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exhibitionPrefecture, organization]);
  useEffect(() => {
    void handleFetchExhibitionProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, exhibitionPrefecture]);

  useEffect(() => {
    void handleFetchPropertyProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization, propertyPrefecture]);

  useEffect(() => {
    void initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {organization && (
            <>
              <HeadBlock />
              <Breadcrumb items={breadcrumbItems} />
              <CompanyExhibitionContext.Provider
                value={{
                  exhibitionPrefecture,
                  exhibitionProducts,
                  handleLoadMoreExhibitionProducts,
                  handleLoadMorePropertyProducts,
                  isFetchingExhibitionProducts,
                  isFetchingPropertyProducts,
                  isLoadingMoreExhibitionProducts,
                  isLoadingMorePropertyProducts,
                  organization,
                  prefectures,
                  productNextLink,
                  propertyPrefecture,
                  propertyProducts,
                  setExhibitionPrefecture,
                  setPropertyPrefecture,
                }}
              >
                <OrganizationInfo />
              </CompanyExhibitionContext.Provider>
              <BottomMenu menuItems={BOTTOM_MENU_ITEMS} />
            </>
          )}
        </>
      )}
    </>
  );
}
