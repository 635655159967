import { Card, Stack, Typography, Button, Divider } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

import { ImageCarousel } from '@app/components/Product/CarouselItem';
import { PropertyDetails } from '@app/components/Product/PropertyDetails';
import { ShowroomList } from '@app/components/Product/ShowroomList';
import { StrengthsSection } from '@app/components/Product/StrengthsSection';
import { useDeviceType } from '@app/hooks/useBrowserHooks';
import { theme } from '@app/theme';
import { Product } from '@app/types/catalog';

interface ResultItemCardProps {
  onClick?: () => void;
  product: Product;
  showPropertyDetails: boolean;
}

export function ResultItemCard({
  onClick,
  product,
  showPropertyDetails,
}: ResultItemCardProps): ReactElement {
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();

  const handleNavigationClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    navigate(`/properties/${product.id}/inquiry/create`);
  };

  return (
    <Card
      sx={{
        borderRadius: 0,
        cursor: onClick ? 'pointer' : 'default',
        margin: '0 auto',
        maxWidth: '1200px',
        mb: 6,
        width: '100%',
      }}
      data-e2e="searchResult-listItem"
      onClick={onClick}
    >
      <Stack spacing={2} sx={{ p: 2 }}>
        <Typography
          variant="h6"
          color={theme.palette.primary.dark}
          sx={{ textDecoration: 'underline' }}
        >
          {product.name}
        </Typography>

        <ImageCarousel
          images={product.images.map((img) => img.url)}
          displayMode="carousel"
          carouselWidth={isMobile ? 'calc(100vw - 64px)' : undefined}
          carouselMaxWidth={isMobile ? undefined : '980px'}
          carouselMinWidth={isMobile ? undefined : '680px'}
        />

        {showPropertyDetails && <PropertyDetails product={product} />}

        <StrengthsSection
          features={product.features}
          strengthsTitle={product.additionalInformation}
          strengthsDescription={product.description}
          showReadMore={true}
        />

        {showPropertyDetails && (
          <Button
            variant="contained"
            color="primary"
            sx={{ alignSelf: 'flex-start', px: 4, width: 'auto' }}
            onClick={handleNavigationClick}
          >
            見学を予約する
          </Button>
        )}

        <Divider sx={{ mt: 2 }} />

        {!showPropertyDetails && (
          <ShowroomList organizationId={product.organizationId} />
        )}
      </Stack>
    </Card>
  );
}
