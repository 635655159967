import { Grid, Typography, Box, Stack } from '@mui/material';
import React, { ReactElement } from 'react';

import { theme } from '@app/theme';
import { Product } from '@app/types/catalog';
import {
  formatAreaRange,
  formatPrice,
  formatPriceRange,
} from '@app/utils/format';

interface ProductCustomFieldsProps {
  product: Product;
}

export function PropertyDetails({
  product,
}: ProductCustomFieldsProps): ReactElement {
  return (
    <Box sx={{ width: 1 }}>
      <Grid container direction="column">
        <Grid item>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {formatPriceRange(
              product.customFields.minPrice
                ? Number(product.customFields.minPrice)
                : undefined,
              product.customFields.maxPrice
                ? Number(product.customFields.maxPrice)
                : undefined,
              formatPrice,
              product.customFields.priceRangeType
            )}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">
            {formatAreaRange(
              product.customFields.minLandArea,
              product.customFields.maxLandArea,
              product.customFields.landAreaRangeType
            )}
          </Typography>
        </Grid>
      </Grid>

      <Box
        sx={{
          border: `1px solid ${theme.palette.neutral.silver}`,
          borderRadius: 1,
          mt: 2,
          padding: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                交通
              </Typography>
              <Typography variant="body2">
                {product.customFields.access}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                構造/階数
              </Typography>
              <Typography variant="body2">
                {product.customFields.buildingSystem}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                住所
              </Typography>
              <Typography variant="body2">
                {product.addressLine1 + product.addressLine2}
                {!!product.customFields.addressLine3HiddenFlag &&
                  product.addressLine3}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="row" spacing={2}>
              <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                販売戸数
              </Typography>
              <Typography variant="body2">
                {product.customFields.salesHouseCount}戸
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
