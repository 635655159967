import { Organization } from './organization';

import { EmptyKey } from '@app/types/common';

/**
 * Category
 */
export interface Category {
  children: Category[];
  description: string;
  icon?: {
    type: string;
    value: string;
  };
  id: string;
  name: string;
  numberOfProducts: number;
  parent: null | {
    uri: string;
  };
  status: CategoryStatusKey;
}

export interface CategoryWithCount extends Category {
  children: (Category & { count: number })[];
}

export interface LocationWithCount extends ProductLocation {
  count: number;
}

export interface CategoryProductCount {
  count: number;
  id: string;
}

export interface LocationProductCount {
  count: number;
  id: string;
}

export type CategoryState = {
  id: string;
  name: string;
};

export const CategoryType = {
  EXHIBITION: 'exhibition',
  PROPERTY: 'property',
} as const;
export type CategoryTypeKey = (typeof CategoryType)[keyof typeof CategoryType];

export const CategoryName = {
  EXHIBITION: '住宅展示場',
  PROPERTY: '分譲',
} as const;
export type CategoryNameKey = (typeof CategoryName)[keyof typeof CategoryName];

export const CategoryStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
} as const;
export type CategoryStatusKey =
  (typeof CategoryStatus)[keyof typeof CategoryStatus];

export const CategoryIconType = {
  MATERIAL_ICON: 'materialIcon',
  MATERIAL_SYMBOLS: 'materialSymbols',
} as const;
export type CategoryIconTypeKey =
  (typeof CategoryIconType)[keyof typeof CategoryIconType];

/**
 * Product
 */
export interface Product {
  additionalInformation: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  attributes: Attribute[];
  category?: { id: string; name: string };
  categoryId: string;
  customFields: ProductCustomFields;
  description: string;
  dimensions: string;
  favorite: boolean;
  features: ProductFeature[];
  id: string;
  imageIds?: string[];
  images: Attachment[];
  isPromoted?: boolean;
  locationIds: string[];
  locations: ProductLocation[];
  name: string;
  organization?: Organization | string;
  organizationId: string;
  publication: ProductPublication;
  tags: string[];
  variantNote: string;
  variants: ProductVariant[];
}

export interface ProductCustomFields {
  access: string;
  addressLine3HiddenFlag: boolean;
  addressLine4: string;
  areaRatio: string;
  breakTime: string;
  breakTimeNoParticular: boolean;
  buildingAreaRangeType: ProductRangeTypeKey;
  buildingAreaType: ProductLandAreaTypeKey;
  buildingConfirmationNumber: string;
  buildingPermitReason: string;
  buildingSystem: string;
  calculatedDairyPay: number;
  calculatedHourlyPay: number;
  closingTime: string;
  commutingCost: number;
  companyName: string;
  completionDate: string;
  completionDateType: ProductCompletionDateTypeKey;
  completionSeason: ProductCompletionSeasonKey;
  conditionsTransaction: string;
  constructionCompany: string;
  contactAdditional: string;
  contactAddress: string;
  contactPhoneNumber: string;
  count: number;
  day: string;
  deliveryDate: string;
  deliveryDateType: ProductDeliveryDateTypeKey;
  deliveryNote: string;
  deliverySeason: ProductDeliverySeasonKey;
  endTime: string;
  eventContent: string;
  eventDateSince: string;
  eventDateUntil: string;
  eventName: string;
  eventTimeSince: string;
  eventTimeUntil: string;
  facility: string;
  facilityCost: string;
  // 間取り、モデルハウス情報(仮)
  floorPlanDescription1: string;
  floorPlanDescription2: string;
  floorPlanDescription3: string;
  floorPlanDescription4: string;
  floorPlanDescription5: string;
  floorPlanImage1: string;
  floorPlanImage2: string;
  floorPlanImage3: string;
  floorPlanImage4: string;
  floorPlanImage5: string;
  floorPlanRangeType: string;
  floorPlanRooms: string[];
  floorPlanTitle1: string;
  floorPlanTitle2: string;
  floorPlanTitle3: string;
  floorPlanTitle4: string;
  floorPlanTitle5: string;
  floorPlanTypeAdditional: string;
  floorPlanTypeMax: string;
  floorPlanTypeMin: string;
  landAreaRangeType: ProductRangeTypeKey;
  landAreaType: ProductLandAreaTypeKey;
  landCategory: string;
  landRightType: string;
  legalOtherLimitationNote: string;
  legalOtherLimitations: string[];
  lotteryDate: string;
  mainImages: [];
  maxBuildingArea: number;
  maxLandArea: number;
  maxPrice: number;
  maxRoadWidth: string;
  minBuildingArea: number;
  minLandArea: number;
  minPrice: number;
  minRoadWidth: string;
  mostPriceRangeAdditional: string;
  mostPriceRangeCount: string;
  mostPriceRanges: string[];
  openingTime: string;
  orderConditions: string;
  otherCost: string;
  overWorkConditions: string[];
  priceAdditional: string;
  priceNote: string;
  priceRangeType?: ProductRangeTypeKey;
  reformExterior: ProductReformExteriorKey;
  reformInterior: ProductReformInteriorKey;
  regularHoliday: string;
  regularHolidayAdditional: string;
  repeatDetail: string;
  repeatWeek: number[];
  roadNote: string;
  roadPainting: string;
  roadWidthRangeType: string;
  salesHouseCount: number;
  salesScheduleComment: string;
  salesStartDate: string;
  salesType: ProductSalesTypeKey;
  selection: string;
  startTime: string;
  strengths: string[];
  strengthsDescription: string;
  strengthsTitle: string;
  totalHouseCount: number;
  tsuboNotationFlag: boolean;
  useDistrict: [];
  workAddress1: string;
  workAddress2: string;
  workPostalCode: string;
  workPublicHoliday: boolean;
}

export interface ProductPublication {
  publishedAt?: string;
  publishedBy?: string;
  since?: string;
  status: ProductPublicationStatusKey;
  until?: string;
}

export interface SearchResultProducts {
  data: Product[];
  nextLink: string;
  scrollY?: number;
  total: number;
}

export interface SearchCondition {
  access?: string;
  categoryId?: string | null;
  floorPlanRooms?: number[];
  locationIds?: string[];
  maxBuildingArea?: string;
  maxLandArea?: string;
  maxPrice?: string;
  minBuildingArea?: string;
  minLandArea?: string;
  minPrice?: string;
  orderBy?: string;
  organizationId?: string[];
  variant?: {
    max?: string;
    min?: string;
    type?: string;
  };
}

export interface ProductPrice {
  amount: number;
  taxable: boolean;
}

export interface ProductImage {
  alt?: string;
  createdAt?: string;
  id?: string;
  productId?: string;
  src: string;
  updatedAt?: string;
  variantIds?: string[];
}

/**
 * Product Variant
 */
export interface ProductVariant {
  customFields?: ProductVariantCustomFields;
  description?: string;
  id: string;
  images?: Attachment[];
  price: VariantPrice;
  sku?: ProductVariantSkuKey;
  title: string;
}

export interface VariantPrice {
  amount: number;
  currency: string;
  taxIncluded: boolean;
}

export interface ProductVariantCustomFields {
  buildingArea?: string;
  comment?: string;
  exclusiveArea?: string;
  floorPlan?: string;
  landArea?: string;
  price?: string;
}

export const ProductVariantSku = {
  AROUND: 'around', // 周辺環境
  EQUIPMENT: 'equipment', // 設備
  EXTERIOR: 'exterior', // 外観
  FLOOR_PLAN: 'floorPlan', // 間取り
  INTERIOR: 'interior', // 内観
} as const;
export type ProductVariantSkuKey =
  (typeof ProductVariantSku)[keyof typeof ProductVariantSku];

/**
 * attachment
 */
export interface Attachment {
  id: string;
  isPublic: boolean;
  ownerId: string;
  ownerType: string;
  type: string;
  url: string;
}

export interface ProductFeature {
  name: string;
  value: string;
}

export const ProductType = {
  REGULAR_PART_TIME: 'regularPartTime',
  SPOT: 'spot',
} as const;
export type ProductTypeKey = (typeof ProductType)[keyof typeof ProductType];

export const ProductPublicationStatus = {
  ACTIVE: 'ACTIVE',
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
} as const;
export type ProductPublicationStatusKey =
  (typeof ProductPublicationStatus)[keyof typeof ProductPublicationStatus];

export const ProductCommuting = {
  FIXED_FEE: 'FixedFee',
  INCLUDE: 'Include',
  NO_FEE: 'NoFee',
  NO_UPPER_LIMIT: 'NoUpperLimit',
  UPPER_LIMIT: 'UpperLimit',
} as const;
export type ProductCommutingKey =
  (typeof ProductCommuting)[keyof typeof ProductCommuting];
export const ProductCommutingLabel = (
  key: ProductCommutingKey | ''
): string => {
  switch (key) {
    case ProductCommuting.FIXED_FEE:
      return '別途支給(定額)';
    case ProductCommuting.INCLUDE:
      return '交通費込み(所定金額)';
    case ProductCommuting.NO_FEE:
      return 'なし';
    case ProductCommuting.NO_UPPER_LIMIT:
      return '別途支給(上限なし)';
    case ProductCommuting.UPPER_LIMIT:
      return '別途支給(上限金額)';
  }
  return '';
};

/**
 * Attribute
 */
export interface Attribute {
  attributeId?: string;
  categoryIds: string[];
  children?: Attribute[];
  customFields?: AttributeCustomFields;
  groupName: string;
  id: string;
  items: AttributeItem[];
  name: string;
  order: number;
  type: string;
}

export interface AttributeCustomFields {
  icon?: {
    type: string;
    value: string;
  };
  keywords?: string;
}

export interface AttributeItem {
  groupName: string;
  key: string;
  value: string;
}

export const AttributeGroupName = {
  CLINICAL_DEPARTMENT: 'clinicalDepartment',
  JOB_TYPE: 'jobType',
} as const;
export type AttributeGroupNameKey =
  (typeof AttributeGroupName)[keyof typeof AttributeGroupName];

/**
 * Location
 */
export interface City {
  city: string;
  cityCode: string;
}

export interface ProductLocation {
  children?: ProductLocation[];
  code: string | null;
  createdAt?: string;
  id: string;
  name: string;
  numberOfProducts?: number;
  type: ProductLocationTypeKey;
  updatedAt?: string;
}

export const ProductLocationType = {
  CITY: 'city',
  PREFECTURE: 'prefecture',
  REGION: 'region',
} as const;
export type ProductLocationTypeKey =
  (typeof ProductLocationType)[keyof typeof ProductLocationType];

export const ProductSalesType = {
  FCFS: { key: 'fcfs', label: '先着順' },
  LOTTERY: { key: 'lottery', label: '登録抽選' },
  PENDING: { key: 'pending', label: '未定' },
} as const;
export type ProductSalesTypeKey =
  (typeof ProductSalesType)[keyof typeof ProductSalesType]['key'];

export const ProductLandAreaType = {
  REGISTRY: { key: 'registry', label: '登記' },
  SURVEY: { key: 'survey', label: '実測' },
} as const;
export type ProductLandAreaTypeKey =
  (typeof ProductLandAreaType)[keyof typeof ProductLandAreaType]['key'];

export const ProductCompletionDateType = {
  COMPLETED: { key: 'completed', label: '完成済み' },
  COMPLETION_PLAN: { key: 'completionPlan', label: '完成予定' },
  CONTRACTED: { key: 'contracted', label: '契約後' },
} as const;
export type ProductCompletionDateTypeKey =
  (typeof ProductCompletionDateType)[keyof typeof ProductCompletionDateType]['key'];

export const ProductCompletionSeason = {
  EARLY: { key: 'early', label: '初旬' },
  END: { key: 'end', label: '末' },
  LATE: { key: 'late', label: '下旬' },
  MIDDLE: { key: 'middle', label: '中旬' },
} as const;
export type ProductCompletionSeasonKey =
  (typeof ProductCompletionSeason)[keyof typeof ProductCompletionSeason]['key'];

export const ProductDeliveryDateType = {
  CONSULTATION: { key: 'consultation', label: '相談' },
  CONTRACTED: { key: 'contracted', label: '契約後' },
  IMMEDIATE: { key: 'immediate', label: '即引渡可' },
  SPECIFIC: { key: 'specific', label: '指定有' },
} as const;
export type ProductDeliveryDateTypeKey =
  (typeof ProductDeliveryDateType)[keyof typeof ProductDeliveryDateType]['key'];

export const ProductDeliverySeason = {
  EARLY: { key: 'early', label: '初旬' },
  END: { key: 'end', label: '末' },
  LATE: { key: 'late', label: '下旬' },
  MIDDLE: { key: 'middle', label: '中旬' },
} as const;
export type ProductDeliverySeasonKey =
  (typeof ProductDeliverySeason)[keyof typeof ProductDeliverySeason]['key'];

export const ProductReformInterior = {
  COMPLETION_PLAN: { key: 'completionPlan', label: '完了予定' },
  CONTRACTED: { key: 'completed', label: '済' },
  NONE: { key: 'none', label: '無' },
} as const;
export type ProductReformInteriorKey =
  (typeof ProductReformInterior)[keyof typeof ProductReformInterior]['key'];

export const ProductReformExterior = {
  COMPLETION_PLAN: { key: 'completionPlan', label: '完了予定' },
  CONTRACTED: { key: 'completed', label: '済' },
  NONE: { key: 'none', label: '無' },
} as const;
export type ProductReformExteriorKey =
  (typeof ProductReformInterior)[keyof typeof ProductReformInterior]['key'];

export const ProductEventTitle = {
  LOCAL_GUIDE: 'localGuide', // 現地案内会
  LOCAL_SALE: 'localSale', // 現地販売会
  LOCAL_TOUR: 'localTour', // 現地見学会
  MODEL_HOUSE: 'modelHouse', // モデルハウス
  MODEL_ROOM: 'modelRoom', // モデルルーム
  OPEN_HOUSE: 'openHouse', // オープンハウス
  OPEN_ROOM: 'openRoom', // オープンルーム
} as const;
export type ProductEventTitleKey =
  | (typeof ProductEventTitle)[keyof typeof ProductEventTitle]
  | EmptyKey;

export const ProductLandRightType = {
  FIXED_GROUND: 'fixedGround', // 定期地上
  FIXED_LEASE: 'fixedLease', // 定期賃借
  OLD_LAW_GROUND: 'oldLawGround', // 旧法地上
  OLD_LAW_LEASE: 'oldLawLease', // 旧法賃借
  OWNERSHIP: 'ownership', // 所有権
  REGULAR_GROUND: 'regularGround', // 普通地上
  REGULAR_LEASE: 'regularLease', // 普通賃借
} as const;
export type ProductLandRightTypeKey =
  | (typeof ProductLandRightType)[keyof typeof ProductLandRightType]
  | EmptyKey;

export const ProductRangeType = {
  BETWEEN: 'between',
  SEPARATE: 'separate',
} as const;
export type ProductRangeTypeKey =
  | (typeof ProductRangeType)[keyof typeof ProductRangeType]
  | EmptyKey;

/**
 * Search Conditions
 */
export interface Conditions {
  access?: string;
  floorPlanRooms?: number[];
  locationIds: string[];
  maxBuildingArea?: number;
  maxLandArea?: number;
  maxPrice?: number;
  minBuildingArea?: number;
  minLandArea?: number;
  minPrice?: number;
  organizationId?: string[];
}
