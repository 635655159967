import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  Typography,
  useTheme,
  Card,
  CardContent,
  Divider,
} from '@mui/material';
import { sum } from 'lodash';
import { ReactElement, useState, useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { getParentOrganizations } from '@app/adapter/organization-service';
import { locationsTreeSelector } from '@app/domain/catalog';
import { ROOM_TYPE_OPTIONS } from '@app/static/constants';
import { Conditions } from '@app/types/catalog';
import { Organization } from '@app/types/organization';
import { getEllipsisStyle } from '@app/utils/format';

// 数値範囲を未設定の項目に表示する文言
const UNLIMITED_UPPER = '上限なし';
const UNLIMITED_LOWER = '下限なし';

interface SearchConditionSummaryProps {
  condition?: Conditions;
  onSearch: (conditions: Conditions) => void;
  showPropertySearch: boolean;
}

// デフォルトの検索条件
const defaultCondition: Conditions = {
  locationIds: [],
  organizationId: [],
};

/**
 * 検索条件を表示するコンポーネントを返す
 * @param param - 現在の検索条件
 * @returns 検索条件を表示するコンポーネント
 */
export function SearchConditionSummary({
  condition = defaultCondition,
  showPropertySearch,
}: SearchConditionSummaryProps): ReactElement {
  // 現在のURL
  const location = useLocation();
  // 検索条件のクエリパラメーター
  const queryParams = new URLSearchParams(location.search);
  // MUIのテーマ
  const theme = useTheme();
  // 地域の情報
  const locations = useRecoilValue(locationsTreeSelector);
  // 検索条件
  const {
    locationIds: selectedLocations,
    organizationId: selectedCompanies,
    floorPlanRooms,
    minPrice,
    maxPrice,
    minLandArea,
    maxLandArea,
    minBuildingArea,
    maxBuildingArea,
  } = condition;
  // 組織の情報
  const [organizations, setOrganizations] = useState<Organization[]>([]);

  /**
   * 組織の情報を取得する
   */
  const fetchOrganizations = useCallback(async () => {
    try {
      const response = await getParentOrganizations();
      setOrganizations(response.data.value);
    } catch (error) {
      console.error('Error fetching organizations:', error);
    }
  }, []);

  // 描画時に組織の情報を取得
  useEffect(() => {
    void fetchOrganizations();
  }, [fetchOrganizations]);

  // 地方名のID
  const locationIds = locations.map((location) => location.id);

  // 条件の数
  const numOfConditions = sum([
    // 地方名のIDは都道府県のIDと重複カウントとなるため排除する
    selectedLocations.filter(
      (selectedLocationId) => !locationIds.includes(selectedLocationId)
    ).length,
    selectedCompanies?.length,
    floorPlanRooms?.length,
    minPrice ? 1 : 0,
    maxPrice ? 1 : 0,
    minLandArea ? 1 : 0,
    maxLandArea ? 1 : 0,
    minBuildingArea ? 1 : 0,
    maxBuildingArea ? 1 : 0,
  ]);

  // コンポーネント
  return (
    <Card
      sx={{
        border: '1px solid',
        borderColor: theme.palette.divider,
        borderRadius: '16px',
        m: 2,
        ml: 4,
        width: 'calc(100vw - 64px)',
      }}
    >
      <CardContent sx={{ padding: 0 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          sx={{ backgroundColor: '#e3f2fd' }}
        >
          <Typography
            variant="subtitle1"
            component="div"
            textAlign="center"
            color={theme.palette.primary.main}
            py={1}
            fontWeight="bold"
          >
            あなたの検索条件
          </Typography>
          <Typography
            component="div"
            fontSize="12px"
            color={theme.palette.primary.main}
          >
            条件数：{numOfConditions}
          </Typography>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" p={2}>
          <Box>
            <Typography
              variant="subtitle1"
              gutterBottom
              fontWeight="bold"
              pt={1}
            >
              建築エリア
            </Typography>
            <Typography fontSize="14px" sx={getEllipsisStyle(1)}>
              {selectedLocations.length
                ? locations
                    .flatMap((region) =>
                      region.children
                        ?.filter((child) =>
                          selectedLocations.includes(child.id)
                        )
                        .map((child) => child.name)
                    )
                    .join('・')
                : '-'}
            </Typography>
            {!showPropertySearch && (
              <>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  fontWeight="bold"
                  pt={1}
                >
                  企業
                </Typography>
                <Typography fontSize="14px" sx={getEllipsisStyle(1)}>
                  {selectedCompanies?.length
                    ? organizations
                        .filter((organization) =>
                          selectedCompanies.includes(organization.id)
                        )
                        .map((organization) => organization.name)
                        .join('・')
                    : '-'}
                </Typography>
              </>
            )}
            {showPropertySearch && (
              <>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  fontWeight="bold"
                  pt={1}
                >
                  価格
                </Typography>
                <Typography fontSize="14px" sx={getEllipsisStyle(1)}>
                  {minPrice
                    ? minPrice === 10000
                      ? '1億円以上'
                      : `${minPrice}万円以上`
                    : UNLIMITED_LOWER}{' '}
                  ~{' '}
                  {maxPrice
                    ? maxPrice === 10000
                      ? '1億円未満'
                      : `${maxPrice}万円未満`
                    : UNLIMITED_UPPER}
                </Typography>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  fontWeight="bold"
                  pt={1}
                >
                  土地面積
                </Typography>
                <Typography fontSize="14px" sx={getEllipsisStyle(1)}>
                  {minLandArea ? `${minLandArea}㎡以上` : UNLIMITED_LOWER} ~{' '}
                  {maxLandArea ? `${maxLandArea}㎡未満` : UNLIMITED_UPPER}
                </Typography>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  fontWeight="bold"
                  pt={1}
                >
                  建物面積
                </Typography>
                <Typography fontSize="14px" sx={getEllipsisStyle(1)}>
                  {minBuildingArea
                    ? `${minBuildingArea}㎡以上`
                    : UNLIMITED_LOWER}{' '}
                  ~{' '}
                  {maxBuildingArea
                    ? `${maxBuildingArea}㎡未満`
                    : UNLIMITED_UPPER}
                </Typography>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  fontWeight="bold"
                  pt={1}
                >
                  間取り
                </Typography>
                <Typography fontSize="14px" sx={getEllipsisStyle(1)}>
                  {floorPlanRooms?.length
                    ? ROOM_TYPE_OPTIONS.filter((type) =>
                        floorPlanRooms.includes(type.value)
                      )
                        .map((type) => type.label)
                        .join('・')
                    : '-'}
                </Typography>
              </>
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize="12px"
            color={theme.palette.primary.main}
            whiteSpace="nowrap"
            ml={1}
          >
            <Link
              to={`${location.pathname}/search?${queryParams.toString()}`}
              style={{
                alignItems: 'center',
                color: 'inherit',
                display: 'flex',
                textDecoration: 'none',
              }}
            >
              変更する
              <ChevronRightIcon fontSize="small" />
            </Link>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
